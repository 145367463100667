import {combineReducers} from 'redux'
import session from './session'
import ads from './ads'
import adStatus from './adStatus'
import orders from './orders'
import deposits from './deposits'
import marketplacefilter from './marketplacefilter'
import products from './products'
import infoMessages from './infoMessages'
import depositors from './depositors'
import depositor from './depositor'
import signatureStatusIsLoading from './signatureStatusIsLoading'
import banks from './banks'
import postalCodeSearchData from './postalCodeSearchData'
import newDepositor from './newDepositor'
import identificationDocument from './identificationDocument'
import confirmationModal from './confirmationModal'
import confirmModal from './confirmModal'
import interestRateBenchmarks from './interestRateBenchmarks'
import signatureProcess from './signatureProcess'
import bankDocuments from './bankdocuments'
import profile from './profile'
import notificationSettings from './notificationSettings'
import loaded from './loaded'
import portfolioFilter from './portfolioFilter'
import depositorNames from './depositorNames'
import ping from './ping'
import documents from './documents'
import timeoutCountdown from './timeoutCountdown'
import interestRateChange from './interestRateChange'
import newCustomerDeclarations from './newCustomerDeclarations'
import history from './history'
import dashboard from './dashboard'
import supportPane from './supportPane'
import bank from './bank'
import commandIds from './commandIds'
import messages from './messages'
import depositorOffers from './depositorOffers'
import missingAccountStatements from './missingAccountStatements'
import partner from './partner'
import partnerNames from './partnerNames'
import invoiceReports from './invoiceReports'
import bankCalendar from './bankCalendar'
import depositDeviations from './depositDeviations'
import companyPublicInformation from './companyPublicInformation'
import funds from './funds'
import fundBuyOrders from './fundBuyOrders'
import fundSellOrders from './fundSellOrders'
import fundPlacements from './fundPlacements'
import fundCustomers from '#state/reducers/fundCustomers'
import unresolvedTasks from '#state/reducers/unresolvedTasks'
import defaultFundPlatformFees from '#state/reducers/defaultFundPlatformFees'
import uiSettings from '#state/reducers/uiSettings'
import shoppingCart from '#state/reducers/shoppingCart'

const createRootReducer = () => combineReducers({
    session,
    ads,
    adStatus,
    orders,
    deposits,
    marketplacefilter,
    products,
    infoMessages,
    depositors,
    depositor,
    signatureStatusIsLoading,
    banks,
    postalCodeSearchData,
    newDepositor,
    identificationDocument,
    confirmationModal,
    confirmModal,
    interestRateBenchmarks,
    signatureProcess,
    bankDocuments,
    profile,
    notificationSettings,
    loaded,
    portfolioFilter,
    depositorNames,
    ping,
    documents,
    timeoutCountdown,
    interestRateChange,
    newCustomerDeclarations,
    history,
    dashboard,
    supportPane,
    bank,
    commandIds,
    messages,
    depositorOffers,
    missingAccountStatements,
    partner,
    partnerNames,
    invoiceReports,
    bankCalendar,
    depositDeviations,
    companyPublicInformation,
    funds,
    fundBuyOrders,
    fundSellOrders,
    fundPlacements,
    fundCustomers,
    unresolvedTasks,
    defaultFundPlatformFees,
    uiSettings,
    shoppingCart,
})

const CLEAR_REDUX_STORE = 'CLEAR_REDUX_STORE'
const CLEAR_REDUX_STORE_IDENTITY_DATA = 'CLEAR_REDUX_STORE_IDENTITY_DATA'
export const clearReduxStore = () => ({type: CLEAR_REDUX_STORE})
export const clearReduxStoreIdentityData = () => ({type: CLEAR_REDUX_STORE_IDENTITY_DATA})

const createRootReducerWithClearAction = () => {

    // Slices in Redux
    const SLICES_WITHOUT_IDENTITY_DATA = ['session', 'banks', 'ads', 'adStatus', 'products', 'nibor', 'bankCalendar', 'supportPane', 'ping', 'timeoutCountdown', 'defaultFundPlatformFees', 'interestRateBenchmarks', 'loaded'/* NB! loaded will be patched */]

    const rootReducerWithClearAction = (state, action) => {
        if (action.type === CLEAR_REDUX_STORE) {
            const keys = Object.keys(state)
            console.log('Clearing redux store')
            keys.filter(key => key !== 'session')
                .forEach((key) => {
                    delete state[key]
                })
        }

        if (action.type === CLEAR_REDUX_STORE_IDENTITY_DATA) {
            const keys = Object.keys(state)
            console.log('Clearing identity releated data from redux store')
            const patchedLoaded = state.loaded ? {...state.loaded} : {}
            keys.filter(key => !SLICES_WITHOUT_IDENTITY_DATA.includes(key))
                .forEach((key) => {
                    delete state[key]
                    if (state.loaded && state.loaded[key]) {
                        patchedLoaded[key] = false
                    }
                })
            state.loaded = patchedLoaded
        }

        const rootReducer = createRootReducer()

        return rootReducer(state, action)
    }

    return rootReducerWithClearAction

}

export default createRootReducerWithClearAction
