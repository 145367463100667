import styles from './HelpIcon.module.scss'
import {QuestionMarkIcon} from './QuestionMarkIcon'
import classNames from 'classnames'
import {useTranslation} from "#components/i18n";
import {useTracking} from "react-tracking"
import {useSelector} from '#state/useSelector'
import useSupportPane from '#services/useSupportPane'
import { Box, IconButton, SxProps } from '@mui/material'
import { PURPLE } from '#app/colors/colors'

function HelpIcon({
    supportArticleId,
    inline = false,
    className = undefined,
    helpContextPage = undefined,
    inlineText = undefined,
    sx = undefined} : {
        supportArticleId: string,
        inline?: boolean,
        className?: string,
        helpContextPage?: string,
        inlineText?: string,
        sx?: SxProps
    }) {

    const session = useSelector(state => state.session)
    const {trackEvent} = useTracking({ page: helpContextPage, session })
    const supportPane = useSupportPane()
    const {t} = useTranslation('components-HelpIcon')

    const showSupportArticle = () => {
        trackEvent({event: 'open-context-help'})
        supportPane.show(supportArticleId)
    }

    if (inline) {
        return (
            <Box component={'span'} sx={{cursor: 'pointer', color: PURPLE[500], fontWeight: '600', ...sx}} onClick={showSupportArticle}>
                {!inlineText && (
                    t('readMore')
                )}
                {inlineText && inlineText}
            </Box>
        )
    } else {
        return (
            <IconButton color="primary" onClick={showSupportArticle} sx={{fontSize: "2.6rem", ...sx}}>
                <i className='ri-question-line'/>
            </IconButton>
        )
    }
}

export default HelpIcon
