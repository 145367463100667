import {createSlice} from '@reduxjs/toolkit'

const slice = createSlice({
    name: 'ping',
    initialState: [],
    reducers: {
        addPing(state, action) {
            return [
                ...state,
                action.payload
            ]
        },
        clearAllPings() {
            return []
        },
    },
})

const {actions, reducer} = slice
export const {addPing, clearAllPings} = actions
export default reducer
