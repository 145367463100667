const SUPPORT_PAGE_FOR_URL = {
    '/organizations': '2f67237e-5e9b-468b-bb2a-df988464e3c6',
    '/organizations/:uuid/users': '1fbfbc92-3c54-4815-b25a-27470889ada6',
    '/organizations/:uuid/users/authorization/:uuid': '02be945c-fdd0-4352-b45d-dbe8d30fa1c3',
    '/organizations/:uuid/customerdecl': '070a8270-f4de-463e-b492-e4361d775446',
    '/organizations/:uuid/customerdeclaration': '070a8270-f4de-463e-b492-e4361d775446',
    '/organizations/:uuid/accounts': 'e108c90a-2150-4025-aa6a-e2e0401f62e8',
    '/organizations/:uuid/terms': '4cda37c9-7b66-4b44-868c-63a4b071201d',
    '/orders/waiting': '4bdb178a-70e8-419e-97bc-51e133c23723',
    '/bank-dashboard': '38509b86-09c8-4595-893e-ea1d63c73418',
    '/ads': '38509b86-09c8-4595-893e-ea1d63c73418',
    '/interestratechange-bank': '48c6347a-8bc4-4ef3-9760-5a2fdf6c8766',
}

export function getSupportArticleIdForPathname(pathname) {
    const formattedUrl = pathname.replaceAll(/\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/ig, ':uuid')
    return SUPPORT_PAGE_FOR_URL[formattedUrl] || ''
}