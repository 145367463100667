import { PrincipalCandidate } from "#pages/organizations/Authorization/RequestAuthorization";
import {
    DepositorDto,
    OrganisationalPersonDto,
    PartnerCustomerDto,
    SecurityRole,
} from "@fixrate/fixrate-query";
import { validateNationalIdentity } from "./validateFields";

type DepositorOrPartnerCustomer = DepositorDto | PartnerCustomerDto;

export const PersonValidator = {
    // Validation functions for OrganisationalPersonDto
    isInUserList: (person: OrganisationalPersonDto) =>
        !!person.securityRoles?.find((role) => role === "DEPOSITOR_VIEW"),

    isUser: (person: OrganisationalPersonDto) =>
        person.associatedUserId?.length > 0,

    isInvite: (person: OrganisationalPersonDto) =>
        person.associatedInviteId?.length > 0,

    isCustomerDeclarationPerson: (person: OrganisationalPersonDto) =>
        person?.organisationalRoles?.length > 0,

    isBeneficialOwner: (person: OrganisationalPersonDto) =>
        person?.organisationalRoles?.includes("BENEFICIAL_OWNER"),

    isKeyPerson: (person: OrganisationalPersonDto) =>
        person?.organisationalRoles?.includes("KEY_PERSON"),

    isAuthorizationSigner: (person: OrganisationalPersonDto) =>
        person.securityRoles?.includes("DEPOSITOR_SIGN_AUTHORIZATION"),

    isCompleteForAuthorizationCreation: (person: PrincipalCandidate) =>
        person.birthDate != null && !!person.email,

    // Validation functions for DepositorDto and OrganisationalPersonDto
    needsInvite: (depositor: DepositorDto, person: OrganisationalPersonDto) =>
        depositor?.warnings?.some(
            (warning) =>
                (warning.type === "PERSON_NEEDS_INVITE" ||
                    warning.type === "AUTHORIZATION_SIGNER_NEEDS_INVITE") &&
                warning.personId === person.personId
        ),

    PEPInformationIsValid: (person: OrganisationalPersonDto) => {
        const isInCustomerDeclaration = PersonValidator.isCustomerDeclarationPerson(person)
        if (!isInCustomerDeclaration) {
            return true
        }
    
        if (person?.pep === null) {
            return false
        }
    
        if (person?.pep && !person?.politicallyExposedPerson.pepSelf && !person?.politicallyExposedPerson.familyMemberOfPep && !person?.politicallyExposedPerson.colleagueOfPep) {
            return false
        }
    
        if (person?.pep && (!person?.pepPosition || person?.pepInvestingPrivateFunds === null)) {
            return false
        }
    
        return true
    },

    OwnershipInformationIsValid: (person: OrganisationalPersonDto) => {
        const isBeneficialOwner = PersonValidator.isBeneficialOwner(person)

        if (!isBeneficialOwner) {
            return true
        }

        if (!person?.controlShare) {
            return false
        }

        if (!person?.control.other && !person?.control.ownsShares && !person?.control.controlsWithRelatives) {
            return false
        }
    
        return true
    },

    NationalityInformationIsValid: (person: OrganisationalPersonDto) => {
        const isInCustomerDeclaration = PersonValidator.isCustomerDeclarationPerson(person)

        if (!isInCustomerDeclaration) {
            return true
        }

        if (person?.taxResidencies.length === 0) {
            return false
        }

        if (person?.citizenships.length === 0) {
            return false
        }

        if (person?.citizenships.includes("US") && !person?.taxResidencies.some(tr => tr => tr.national === "US")) {
            return false
        }

        if (person?.taxResidencies?.some(tr => validateNationalIdentity(tr.nationality, tr.value) === false)) {
            return false
        }
    
        return true
    },

    ContactInformationIsValid: (person: OrganisationalPersonDto) => {
        const isInCustomerDeclaration = PersonValidator.isCustomerDeclarationPerson(person)

        if (!isInCustomerDeclaration) {
            return true
        }

        if (!person?.address || !person?.birthDate || !person?.firstName || !person?.lastName) {
            return false
        }
    
        return true
    },

    isCompleteForCustomerDeclaration: (
        depositor: DepositorDto,
        person: OrganisationalPersonDto
    ) =>
        !depositor?.warnings?.some(
            (warning) =>
                warning.type === "NEED_INFO_FOR_CUSTOMER_DECLARATION" &&
                warning.personId === person.personId
        ),

    isComplete: (person: OrganisationalPersonDto, depositor: DepositorDto) =>
        !depositor?.warnings?.some(
            (warning) => warning.personId === person.personId
        ),

    isDepositorReadyForCustomerDeclaration: (depositor: DepositorDto) =>
        !depositor?.warnings?.some((warning) =>
            [
                "MISSING_ORGANISATIONAL_ROLES",
                "MISSING_OWNERS_DECLARATION",
                "NEED_INFO_FOR_CUSTOMER_DECLARATION",
            ].includes(warning.type)
        ),

    areSecurityRolesCovered: (depositor: DepositorOrPartnerCustomer) =>
        !depositor?.warnings?.some(
            (warning) => warning.type === "MISSING_SECURITY_ROLES"
        ),

    areOrganisationalRolesCovered: (depositor?: DepositorOrPartnerCustomer) =>
        !depositor?.warnings?.some(
            (warning) => warning.type === "MISSING_ORGANISATIONAL_ROLES"
        ),

    areOwnersCovered: (depositor: DepositorOrPartnerCustomer) =>
        !depositor?.warnings?.some(
            (warning) => warning.type === "MISSING_OWNERS_DECLARATION"
        ),

    anyUserMissingIdentityDocument: (depositor: DepositorDto) =>
        depositor?.warnings?.some(
            (warning) => warning.type === "MISSING_IDENTIFICATION_DOCUMENT"
        ),

    anyMissingSignatures: (depositor: DepositorDto) =>
        depositor?.warnings?.some(
            (warning) => warning.type === "AUTHORIZATION_DOCUMENT_NOT_SIGNED"
        ),

    anyMissingAuthDocuments: (depositor: DepositorDto) =>
        depositor?.warnings?.some(
            (warning) => warning.type === "MISSING_AUTHORIZATION_DOCUMENT"
        ),

    anyMissingAuthDocsOrSignatures: (depositor: DepositorDto) =>
        PersonValidator.anyMissingSignatures(depositor) ||
        PersonValidator.anyMissingAuthDocuments(depositor),

    arePeopleRolesBoardAndDocumentsOk: (depositor: DepositorDto) =>
        PersonValidator.arePeopleComplete(depositor) &&
        PersonValidator.areSecurityRolesCovered(depositor) &&
        PersonValidator.areOrganisationalRolesCovered(depositor) &&
        PersonValidator.areOwnersCovered(depositor) &&
        !PersonValidator.anyUserMissingIdentityDocument(depositor) &&
        !PersonValidator.anyMissingAuthDocsOrSignatures(depositor),

    arePeopleComplete: (depositor: DepositorDto) =>
        depositor?.people.every((person) =>
            PersonValidator.isComplete(person, depositor)
        ),

    hasCustomerDeclarationPersonGalleryChanged: (depositor: DepositorDto) =>
        depositor?.warnings?.some(
            (warning) =>
                warning.type === "CUSTOMER_DECLARATION_PERSON_GALLERY_CHANGED"
        ),

    hasBeneficialOwners: (depositor: DepositorOrPartnerCustomer) =>
        depositor?.people?.some((person) =>
            person?.organisationalRoles?.includes("BENEFICIAL_OWNER")
        ),

    allBeneficialOwnersHasControlShare: (
        depositor: DepositorOrPartnerCustomer
    ) =>
        depositor?.people
            ?.filter((person) =>
                person?.organisationalRoles?.includes("BENEFICIAL_OWNER")
            )
            .every((person) => person.controlShare?.length > 0),

    isCustomCustomerDeclaration: (depositor: DepositorOrPartnerCustomer) =>
        depositor?.premiumProducts?.includes("CUSTOM_CUSTOMER_DECLARATION"),

    isPartner: (person: OrganisationalPersonDto) =>
        [
            "DEPOSITOR_PARTNER_SUPPORT",
            "DEPOSITOR_PARTNER_ADVISOR",
            "DEPOSITOR_PARTNER_ACCOUNTANT",
        ].some((role: SecurityRole) => person.securityRoles.includes(role)),
}
