import {DepositorDto} from '@fixrate/fixrate-query'
import {useSelector} from '#state/useSelector'
import * as selectors from '#state/selectors'
import {useMemo} from 'react'
import {PersonValidator} from '#services/PersonValidator'

interface OrganizationWarnings {
    customerDeclaration: boolean
    terms: boolean
    accounts: boolean
    users: boolean
    integrations: boolean
}

export default function useOrganizationWarnings(depositor: DepositorDto): OrganizationWarnings {
    const depositorSettlementAccounts = useSelector(selectors.depositorSettlementAccounts)
    const documents = useSelector(state => state.documents)

    return useMemo(() => {
        if (!depositor) {
            return {
                customerDeclaration: false,
                terms: false,
                accounts: false,
                users: false,
                integrations: false,
            }
        }

        const customerDeclaration = depositor.customerDeclarationId && documents[depositor.customerDeclarationId]
        const allPersonsAreReady = PersonValidator.isDepositorReadyForCustomerDeclaration(depositor)
        const mustConfirmBrregChanges = depositor.importedPeopleChanges && depositor.importedPeopleChanges.length > 0

        const currencies = depositor?.portfolios?.map(p => p.currency)
        const missingCurrencies = currencies?.filter(c => !depositorSettlementAccounts[depositor.id]?.find(a => a.currency === c || (c === "NOK" && a.currency === null))).length > 0

        const warningIntegrations = false
        const warningTerms = !depositor.termsAccepted
        const warningAccounts = depositorSettlementAccounts[depositor.id]?.length === 0 || missingCurrencies
        const warningUsers = !PersonValidator.arePeopleRolesBoardAndDocumentsOk(depositor) || mustConfirmBrregChanges

        const warningCustomerDecl = !customerDeclaration
            || (customerDeclaration && !customerDeclaration.signedByAny)
            || depositor.customerDeclarationExpired
            || !allPersonsAreReady
            || (!PersonValidator.isCustomCustomerDeclaration(depositor) && PersonValidator.hasCustomerDeclarationPersonGalleryChanged(depositor))

        return {
            customerDeclaration: warningCustomerDecl,
            terms: warningTerms,
            accounts: warningAccounts,
            users: warningUsers,
            integrations: warningIntegrations,
        }
    }, [depositor, documents, depositorSettlementAccounts])
}
