import {
    addMonths,
    eachMonthOfInterval,
    endOfMonth,
    format
} from 'date-fns';
import {i18n, rank} from '#components/i18n';
import en from 'date-fns/locale/en-US';
import nb from 'date-fns/locale/nb';
import {DateAsString, FundPlacementDto} from "@fixrate/fixrate-query";
import addDays from "date-fns/addDays";
import { TFunction } from 'react-i18next';
export const reportPeriodTypes = ['M', 'Q', 'T', 'H', 'Y'];

export type ReportPeriodIdentifier = {
    year: number,
    type: string,
    index: number
}

const formats = {
    'M': 'MMM yyyy',
    'Q': 'QQQ yyyy',
    'Y': 'yyyy'
}

const numMonths = {
    'M': 1,
    'Q': 3,
    'T': 4,
    'H': 6,
    'Y': 12
}

const locales = {
    'en': en,
    'nb': nb
}

const everyNthElement = (arr, n) => arr.filter((_, index) => (index) % n === 0);
const periodIndex = (periodStart: Date, periodType: string) => (1 + Math.floor(periodStart.getMonth() / numMonths[periodType]))

const getPeriodStart = (id: ReportPeriodIdentifier) => (
    addMonths(new Date(id.year, 0, 1), numMonths[id.type] * (id.index - 1))
)

export const formatLongReportPeriod = (periodIdentifier: string, t: TFunction) => {
    const id = parsePeriodIdentifier(periodIdentifier)

    switch (id.type) {
        case 'T':
        case 'H':
        case 'Q':
            return rank(id.index, t, i18n) + ' ' + t(`enum-ReportPeriodUnit.${id.type}`) + ' ' + id.year
        case 'Y':
            return `${id.year}`

        default:
            return format(getPeriodStart(id), formats[id.type], {locale: locales[i18n.language]})
    }
}

export const formatShortReportPeriod = (id: ReportPeriodIdentifier, t: TFunction) => {
    switch (id.type) {
        case 'T':
            return `${t('common.reportPeriod.triannualFormat')}${id.index} ${id.year}`

        case 'H':
            return `${t('common.reportPeriod.halfYearFormat')}${id.index} ${id.year}`

        default:
            return format(getPeriodStart(id), formats[id.type], {locale: locales[i18n.language]})
    }
}

export const getPeriodIdentifier = (periodStart: Date, periodType: string): ReportPeriodIdentifier => ({
    year: periodStart.getFullYear(),
    type: periodType,
    index: periodIndex(periodStart, periodType)
})

export const formatReportPeriodIdentifier = (id: ReportPeriodIdentifier) => {
    switch (id.type) {
        case 'T':
        case 'H':
        case 'Q':
            return `${id.year}-${id.type}${id.index}`

        case 'Y':
            return `${id.year}`

        case 'M':
            return format(getPeriodStart(id), 'yyyy-MM')
    }
}

export const getReportPeriodDates = (periodIdentifier: string): string[] => {
    const {year, type, index} = parsePeriodIdentifier(periodIdentifier)
    const periodStart = new Date(year, (index - 1) * numMonths[type], 1)
    const periodEnd = endOfMonth(addMonths(periodStart, numMonths[type] - 1))
    return [format(periodStart, 'yyyy-MM-dd'), format(periodEnd, 'yyyy-MM-dd')]
}

export const parsePeriodIdentifier = (periodIdentifier: string): ReportPeriodIdentifier => {
    const year = parseInt(periodIdentifier.substring(0, 4))

    if(periodIdentifier.length === 4) {
        return {year, type: 'Y', index: 1}
    } else {
        if(isNaN(parseInt(periodIdentifier.substring(5, 7)))) {
            return {year, type: periodIdentifier.substring(5, 6), index: parseInt(periodIdentifier.substring(6, 7))}
        } else {
            return {year, type: 'M', index: parseInt(periodIdentifier.substring(5, 7))}
        }
    }
}

function findFirstPlacementDate(placements: FundPlacementDto[]): DateAsString {
    return placements
        .flatMap(placement => placement.transactions)
        .map(transaction => transaction.transactionDate)
        .reduce((a, b) => a < b ? a : b, null)
}

function getInterval(firstPlacementDate: DateAsString, lastNavDate: DateAsString, numMonths: number): Interval {
    const now = new Date(lastNavDate);
    const first = new Date(firstPlacementDate);

    // Calculate how many periods have elapsed since the start of the year
    const startPeriod = Math.floor(first.getMonth() / numMonths);
    const endPeriod = Math.floor(now.getMonth() / numMonths);

    const start = new Date(first.getFullYear(), startPeriod * numMonths, 1);

    let end = new Date(now.getFullYear(), endPeriod * numMonths, 1);
    end = addDays(end, -1); // end of the month before

    return {start, end};
}

export function setReportPeriods(reportType: string, setReportPeriods: (periods: Date[]) => void, placements: FundPlacementDto[], navDate: DateAsString) {
    const firstPlacementDate: DateAsString = findFirstPlacementDate(placements)
    if(firstPlacementDate === null) {
        return
    }
    const interval = getInterval(firstPlacementDate, navDate, numMonths[reportType]);
    const periods: Date[] = interval.end > interval.start ? everyNthElement(eachMonthOfInterval(interval), numMonths[reportType]).reverse() : []

    setReportPeriods(periods)
}
