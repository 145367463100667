import {InterestRateBenchmark} from '@fixrate/fixrate-query'
import {TFunction} from 'react-i18next'

function doFormat(interest, decimals = 2) {
    if (interest !== undefined && interest !== null) {
        let formattedInterest = interest;

        if (typeof interest !== 'string') {
            formattedInterest = interest.toFixed(decimals)
        }

        return (formattedInterest + '%').replace('.', ',')
    } else {
        return ''
    }
}

const InterestOutput = () => null

InterestOutput.formatFixed = (interest) => doFormat(interest)

InterestOutput.format = (interest) => {
    return doFormat(interest)
}

InterestOutput.formatWithDecimals = (interest, decimals) => {
    return doFormat(interest, decimals)
}

InterestOutput.benchmarkLabel = (interestRateBenchmark: InterestRateBenchmark, t: TFunction<"translation", undefined>) : string => {
    return t('common.interestRateBenchmark.' + interestRateBenchmark)
}

InterestOutput.formatMargin = (interest) => {
    return doFormat(interest)
}

InterestOutput.formatNoDecimal = (interest) => {
    return doFormat(interest, 0)
}

InterestOutput.formatWithSign = (interest) => {
    return `${interest >= 0 ? '+' : ''}${doFormat(interest)}`
}

InterestOutput.formatMarginWithBenchmark = (interest: number, interestRateBenchmark: InterestRateBenchmark, t: TFunction<"translation", undefined>) => {
    return `${InterestOutput.benchmarkLabel(interestRateBenchmark, t)} ${interest >= 0 ? '+' : '-'} ${doFormat(interest).replace('-','')}`
}

InterestOutput.formatMarginWithBenchmarkObj = (interest: number, interestRateBenchmark: InterestRateBenchmark, t: TFunction<"translation", undefined>) => {
    const labelFormated = InterestOutput.benchmarkLabel(interestRateBenchmark, t);
    const interestFormated = (interest >= 0 ? '+ ' : '- ') + (doFormat(interest).replace('-',''))
    return {
        label: labelFormated,
        interest: interestFormated,
    }
}

InterestOutput.formatRange = (from: number, to: number, decimals: number, collapseIfEqual = false) => {
    return (from === to && collapseIfEqual) ?
        InterestOutput.formatWithDecimals(from, decimals)
        : `${InterestOutput.formatWithDecimals(from, 3)}-${InterestOutput.formatWithDecimals(to, 3)}`
}
export default InterestOutput
