import restEndpoint from './rest/rest-endpoint'
import * as actions from '#state/reducers/supportPane'
import {startLoadingSupportPane, stopLoadingSupportPane} from '#state/reducers/supportPane'
import {useDispatch} from 'react-redux'
import {useTracking} from '#services/useTracking'
import {useSelector} from '#state/useSelector'

const topLevelNotionBlockId = 'ff988016-3346-40fe-8ce6-be558f686df0'

export default function useSupportPane() {

    const dispatch = useDispatch()
    const {trackEvent} = useTracking({page: 'SupportPaneView'})
    const supportPaneData = useSelector(state => state.supportPane)

    const isOpen = supportPaneData.isOpen
    const loaded = (supportPaneData.notionRecordMap && supportPaneData.notionRecordMap.block)

    const notionRecordMap = supportPaneData.notionRecordMap
    const blockId = loaded && Object.keys(supportPaneData.notionRecordMap.block)[0]
    const parentBlockId = loaded && supportPaneData.notionRecordMap.block[blockId]?.value.parent_id
    const title = loaded && supportPaneData.notionRecordMap.block[blockId]?.value.properties.title[0][0]

    async function show(supportArticleHref: string) {
        dispatch(actions.showSupportPane())
        await load(supportArticleHref)
    }

    function hide() {
        dispatch(actions.hideSupportPane())
    }

    async function load(supportArticleHref: string) {

        trackEvent({event: 'link'})
        dispatch(startLoadingSupportPane(findBlockTitle(supportArticleHref)))

        try {
            const notionRecordMap = await restEndpoint(dispatch).getSupportArticleByHref(addLeadingSlash(supportArticleHref))
            dispatch(actions.setNotionRecordMap(notionRecordMap))
        } catch (e) {
            console.error('Failed while loading support article', e)
        } finally {
            dispatch(stopLoadingSupportPane())
        }

    }

    function loadParentPage() {
        return load(parentBlockId)
    }

    function loadTopLevelPage() {
        return load(topLevelNotionBlockId)
    }

    function isTopLevelPage() {
        return blockId === topLevelNotionBlockId
    }

    function findBlockTitle(blockId) {
        const correctlyFormattedBlockId = addDashesToUuid(removeLeadingSlash(blockId))
        return loaded && notionRecordMap.block[correctlyFormattedBlockId]?.value.properties.title[0][0]
    }

    return {
        show,
        showTopLevelPage: () => show(topLevelNotionBlockId),
        hide,
        load,
        isLoading: supportPaneData.isLoading,
        isLoaded: loaded,
        isOpen,
        isTopLevelPage: isTopLevelPage(),
        notionRecordMap,
        loadParentPage,
        loadTopLevelPage,
        title: supportPaneData.loadingTitle || title || '-',
    }

}

function addLeadingSlash(path: string): string {
    return path.startsWith('/') ? path : '/' + path
}

function removeLeadingSlash(path: string): string {
    return path.startsWith('/') ? path.substring(1) : path
}

function addDashesToUuid(uuid: string): string {
    if (uuid.indexOf('-') === -1) {
        return uuid.substring(0, 8) + '-' + uuid.substring(8, 12) + '-' + uuid.substring(12, 16) + '-' + uuid.substring(16, 20) + '-' + uuid.substring(20)
    } else {
        return uuid
    }
}

