import {createSlice} from '@reduxjs/toolkit'

const slice = createSlice({
    name: 'depositorNames',
    initialState: [],
    reducers: {
        setDepositorNames(state, action) {
            return action.payload
        },
    },
})

const {actions, reducer} = slice
export const {setDepositorNames} = actions
export default reducer