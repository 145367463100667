/*
    Register callback functions to be executed when a command is done
*/

const callbacks = {};
const unprocessed = [];

export const commandDone = (commandId, callback) => {
    if (commandId) {
        if (unprocessed.indexOf(commandId) > -1) {
            // A callback is registered that matches a previously stored commandId. Execute now.
            console.log('commandDone(%s): found in unprocessed, using callback immediately', commandId);
            unprocessed.splice(unprocessed.indexOf(commandId), 1);
            callback();
        } else {
            if (callback) {
                callbacks[commandId] = callback
            } else {
                delete callback[commandId]
            }
        }
    }
};

/*
    Delegate commandProcessed event to registered callback
 */
export const handleCommandProcessed = (commandId) => {

    const callback = callbacks[commandId];
    if (callback) {
        delete callback[commandId];
        callback()
    } else {
        console.log('handleCommandProcessed(%s): adding to unprocessed', commandId);
        unprocessed.push(commandId);
    }
};


