import {createSlice} from '@reduxjs/toolkit'

const slice = createSlice({
    name: 'newDepositor',
    initialState: {},
    reducers: {
        setNewDepositor(state, action) {
            return action.payload
        },
    },
})

const {actions, reducer} = slice
export const {setNewDepositor} = actions
export default reducer