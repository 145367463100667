import config from '../config'

const enabled = !!config().hubspot


export function loadHubspot() {
    if (enabled) {
        window.hsConversationsSettings = {
            loadImmediately: false,
        }

        const script = document.createElement('script')
        script.src = `//js.hs-scripts.com/${config().hubspot}.js`
        script.addEventListener('load', () => console.log('Hubspot; Script loaded'))
        script.addEventListener('error', () => console.error('Hubspot: Script failed to load'))

        document.head.appendChild(script)
    }
}

export function setHubspotConversationSettings({authenticated, email, hubspotVisitorToken} : {authenticated: boolean, email: string, hubspotVisitorToken: string}) {
    if (enabled) {
        if (authenticated) {
            window.hsConversationsSettings = {
                identificationEmail: email,
                identificationToken: hubspotVisitorToken || '',
            }
        } else {
            window.hsConversationsSettings = {}
        }
    }
}

export function openChatBubble() {
    if (enabled) {
        window.HubSpotConversations.widget.open()
    } else {
        const hash = window.location.hash
        if (hash.indexOf('hs-chat-open') === -1) {
            window.location.hash = hash + '#hs-chat-open'
        }
    }
}

export function registerCookieConsentCallback(callback: (analytics: boolean, advertisement: boolean, functionality: boolean ) => void) {
    if (!enabled) {
        callback(true, true, true)
    } else {
        const _hsp = window._hsp = window._hsp || [];
        _hsp.push(['addPrivacyConsentListener', function (consent) {
            callback(consent.categories.analytics, consent.categories.advertisement, consent.categories.functionality)
        }]);
    }
}


function loadOrRefreshConversations(getState) {

    const {session} = getState()
    hubspotIdentify(session.email)
    setHubspotConversationSettings(session)

    if (window.HubSpotConversations.widget.status().loaded) {
        console.log('Reloading HubSpot Conversations widget: ' + JSON.stringify(window.hsConversationsSettings))
        window.HubSpotConversations.clear({resetWidget: true})
    } else {
        console.log('Loading HubSpot Conversations widget: ' + JSON.stringify(window.hsConversationsSettings))
        window.HubSpotConversations.widget.load()
    }
}

export function hubspotIdentify(email) {
    if (enabled) {
        console.log('Hubspot: identify', email)
        const _hsq = window._hsq = window._hsq || []
        _hsq.push(["identify", {
            email: email,
        }])

//        console.log('Hubspot: Calling trackPageView to force new identity')
//        _hsq.push(['trackPageView'])
    }
}

export function loginToHubspot(getState) {
    if (enabled) {
        if (window.HubSpotConversations) {
            console.log('The HubSpot Conversations API is ready already')
            loadOrRefreshConversations(getState)
        } else {
            console.log('The HubSpot Conversations API is not ready yet')
            window.hsConversationsOnReady = [
                () => loadOrRefreshConversations(getState),
            ]
        }
    }
}

export function logoutFromHubspot() {
    if (enabled) {
        console.log('Hubspot: Logout')
        if (window.HubSpotConversations) {
            window.HubSpotConversations.clear({resetWidget: true})
        }
    }
}

type HubspotConsentSettings = {
    analytics: string,
    advertisement: string,
    functionality: string
}

let latestTimeoutId;
export function submitHubspotCookieConsent(consentSettings: HubspotConsentSettings) {
    function pushToHSQ() {
        if (!consentSettings.advertisement || !consentSettings.analytics || !consentSettings.functionality) {
            window._hsq.push(['doNotTrack']);
        } else {
            window._hsq.push(['doNotTrack', { track: true }]);
        }
    }

    function waitForHSQ() {
        if (window._hsq && Array.isArray(window._hsq)) {
            pushToHSQ();
        } else {
            latestTimeoutId = setTimeout(waitForHSQ, 100); // Check again after 100ms
        }
    }

    // Clear the previous timeout if any
    if (latestTimeoutId) {
        clearTimeout(latestTimeoutId);
    }

    // Start a new wait process
    waitForHSQ();
}
