import {createSlice} from '@reduxjs/toolkit'
import {SupportPane} from '#state/types'

const DEFAULT_STATE = {
    isOpen: false,
    isLoading: false,
}

const slice = createSlice({
    name: 'supportPane',
    initialState: DEFAULT_STATE,
    reducers: {
        startLoadingSupportPane(state: SupportPane, action) {
            state.isLoading = true
            state.loadingTitle = action.payload
        },
        stopLoadingSupportPane(state: SupportPane) {
            state.isLoading = false
            state.loadingTitle = null
        },
        showSupportPane(state: SupportPane) {
            state.isOpen = true
        },
        hideSupportPane(state: SupportPane) {
            state.isOpen = false
        },
        setCurrentUrl(state: SupportPane, action) {
            state.currentUrl = action.payload
        },
        setNotionRecordMap(state: SupportPane, action) {
            state.notionRecordMap = action.payload
        },
    },
})

const {actions, reducer} = slice
export const {startLoadingSupportPane, stopLoadingSupportPane, showSupportPane, hideSupportPane, setCurrentUrl, setNotionRecordMap} = actions
export default reducer