import {POST_RAW} from '../network/rest-connection'

async function handleStatusCodeErrors(dispatch, response) {
    // Ignore. Delegates to the parse function.
    return response
}

async function handleNetworkErrors(dispatch, ex) {
    console.warn('Network error while sending ping', ex)
    return 'NETWORK_ERROR'
}

async function parse(response: Response) {
    if (!response.ok) {
        if (response.status === 401) {
            return 'LOGGED_OUT'
        } else {
            response.text()
                .then(text => {
                    console.error('Network response', response.statusText, text)
                })
            return 'UNEXPECTED_STATUS'
        }
    }
    return 'OK'
}


const pingCommand = (dispatch) => ({

    send: (pingId) => POST_RAW(
        dispatch,
        '/api/command/ping',
        pingId,
        {
            'Accept': 'text/plain',
            'Content-Type': 'text/plain',
        },
        {
            handleStatusCodeErrors,
            handleErrors: handleNetworkErrors,
            parse
        }

    ),

})

export default pingCommand
