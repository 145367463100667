/*
    Functions for setting and removing fields in deep object structures
    with jsonpath-like field keys

    The functions will replace any affacted objects in the graph with new
    objects and return a new root. The original objects will stay immutable
    and will not be changed.

    Missing fields and objects will be created as needed.
    Arrays will be filled with undefined as needed

    Can handle paths like
    - name
    - person[3]
    - person[3].name

    Will currently NOT handle
    - Root objects that are Arrays (ex. '[1].foo'
    - Arrays of arrays (ex: 'foo[1][2]')

    Ex:
    const original = {}
    const result = setField(original, 'person[2].name.first', 'John')
    // result = {person[undefined, undefined, {name: {first: 'John'}}]}

    const original = {name: 'John'}
    const result = setField(original, 'hobbies[1]', 'Tennis')
    // result = {name: 'John', hobbies: [undefined, 'Tennis']}

    const original = {name: {first: 'John', last: 'Doe'}}}
    const result = removeField(original, 'name.last')
    // result = {name: {first: 'John'}}

    const original = {person[{}, {}, {name: {first: 'John'}}]}
    const result = removeField(original, 'person[2]')
    // result = {person[{}, {}]}

*/
export function setField(root, path, value) {
    const pathElements = path.split('.').filter(s => s.length > 0)
    return traverse(root, pathElements, (object, pathOrIndex) => {
        if (Array.isArray(object)) {
            object[pathOrIndex] = value
            return object
        } else {
            object[pathOrIndex] = value
            return object
        }
    })
}

export function removeField(root, path) {
    const pathElements = path.split('.').filter(s => s.length > 0)
    return traverse(root, pathElements, (object, pathOrIndex) => {
        if (Array.isArray(object)) {
            object.splice(pathOrIndex, 1)
            return object
        } else {
            delete object[pathOrIndex]
            return object
        }
    })
}

// Traverses the object graph while replacing objects
// Executes 'operation' on the last object. 'operation' should modify the object as needed and return the object
// Returns a new graph where only affected elements are exchanged
function traverse(root, pathElements, operation) {
    const path = pathElements[0]
    if (pathElements.length === 1) {
        if (path.endsWith(']')) {
            const [pathName, arrayIndexStr] = path.split(/[[\]]/)
            const arrayIndex = parseInt(arrayIndexStr)
            const oldArray = typeof root[pathName] !== 'undefined' ? root[pathName] : []
            if (!Array.isArray(oldArray)) {
                throw Error(`Path element ${pathName} is not an array`)
            }
            const array = [...oldArray]
            return {
                ...root,
                [pathName]: operation(array, arrayIndex)
            }
        } else {
            const newField = {...root}
            return operation(newField, path)
        }
    } else {
        if (path.endsWith(']')) {
            const [pathName, arrayIndexStr] = path.split(/[[\]]/)
            const arrayIndex = parseInt(arrayIndexStr)
            const oldArray = root[pathName] || []
            if (!Array.isArray(oldArray)) {
                throw Error(`Path element ${pathName} is not an array`)
            }
            const array = [...oldArray]
            let currentField = array[arrayIndex]
            if (typeof currentField === 'undefined') {
                currentField = {}
            }
            array[arrayIndex] = traverse(currentField, pathElements.slice(1), operation)
            return {
                ...root,
                [pathName]: array
            }

        } else {
            let currentField = root[path]
            if (typeof currentField === 'undefined') {
                currentField = {}
            }
            return {
                ...root,
                [path]: traverse(currentField, pathElements.slice(1), operation)
            }
        }
    }
}

export function setField2(root, path, value) {
    const pathElements = path.split(/[.[\]]+/).filter(s => s.length > 0)
    return traverse2(root, pathElements, (object, pathOrIndex) => {
        if (Array.isArray(object)) {
            object[pathOrIndex] = value
            return object
        } else {
            object[pathOrIndex] = value
            return object
        }
    })
}

export function removeField2(root, path) {
    const pathElements = path.split(/[.[\]]+/).filter(s => s.length > 0)
    return traverse2(root, pathElements, (object, pathOrIndex) => {
        if (Array.isArray(object)) {
            object.splice(pathOrIndex, 1)
            return object
        } else {
            delete object[pathOrIndex]
            return object
        }
    })
}

// Traverses the object graph while replacing objects
// Executes 'operation' on the last object. 'operation' should modify the object as needed and return the object
// Returns a new graph where only affected elements are exchanged
function traverse2(root, pathElements, operation) {
    const path = pathElements[0]
    const isArray = pathElements.length > 1 && !isNaN(pathElements[1])
    let arrayIndex, oldArray
    if (isArray) {
        arrayIndex = isArray && parseInt(pathElements[1])
        oldArray = typeof root[path] !== 'undefined' ? root[path] : []
        if (!Array.isArray(oldArray)) {
            throw Error(`Path element ${path} is not an array`)
        }
    }
    if (pathElements.length === 1) {
        if (isArray) {
            const array = [...oldArray]
            return {
                ...root,
                [path]: operation(array, arrayIndex)
            }
        } else {
            const newField = {...root}
            return operation(newField, path)
        }
    } else {
        if (isArray) {
            let currentField = root[path]
            if (typeof currentField === 'undefined') {
                currentField = []
            }
            return {
                ...root,
                [path]: traverse2(currentField, pathElements.slice(1), operation)
            }
        } else {
            let currentField = root[path]
            if (typeof currentField === 'undefined') {
                currentField = {}
            }
            return {
                ...root,
                [path]: traverse2(currentField, pathElements.slice(1), operation)
            }
        }
    }
}

