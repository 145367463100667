/*
    The one and only function that handles websocket messages received over the websocket connection
*/

import {convertStringToDate, setCorrectDate} from '../dateandtime'
import {updateAds} from '#state/reducers/ads'
import {setAdStatus} from '#state/reducers/adStatus'
import {setBank} from '#state/reducers/bank'
import {setBankCalendar} from '#state/reducers/bankCalendar'
import {setBankDocuments} from '#state/reducers/bankdocuments'
import {setBanks} from '#state/reducers/banks'
import {updateCommandId} from '#state/reducers/commandIds'
import {setDepositorNames} from '#state/reducers/depositorNames'
import {setDepositorOffers} from '#state/reducers/depositorOffers'
import {setDepositors} from '#state/reducers/depositors'
import {updateDeposits} from '#state/reducers/deposits'
import {removeDocument, setDocument, setDocuments} from '#state/reducers/documents'
import {setHistory} from '#state/reducers/history'
import {setIdentificationDocument} from '#state/reducers/identificationDocument'
import {setInvoiceReports} from '#state/reducers/invoiceReports'
import {setIsLoaded} from '#state/reducers/loaded'
import {setMessages} from '#state/reducers/messages'
import {setMissingAccountStatements} from '#state/reducers/missingAccountStatements'
import {setNewCustomerDeclarations} from '#state/reducers/newCustomerDeclarations'
import {setNewDepositor} from '#state/reducers/newDepositor'
import {setPartner} from '#state/reducers/partner'
import {setPartnerNames} from '#state/reducers/partnerNames'
import {setProfile} from '#state/reducers/profile'
import {updateSignatureProcess} from '#state/reducers/signatureProcess'
import {setInterestRateChange} from '#state/reducers/interestRateChange'
import {setProducts} from '#state/reducers/products'
import {clearAllPings} from '#state/reducers/ping'
import {updateOrder, updateOrders} from '#state/reducers/orders'
import {setNotificationSettings} from '#state/reducers/notificationSettings'
import {
    setBenchmarkInterestRates,
    setDefaultBenchmarkInterestRate,
    setDefaultInterestRateBenchmark,
} from '#state/reducers/interestRateBenchmarks'
import {updateDashboard} from '#state/reducers/dashboard'
import {handleCommandProcessed} from './commandDone'
import {resolveCommand} from '../beta/waitForCommandResponse'
import {calculateAdStatus} from '#services/adactive'
import {ProcessHistoryDto} from '@fixrate/fixrate-query'
import {updateDepositDeviations} from '#state/reducers/depositDeviations'
import {setCompanyPublicInformation} from '#state/reducers/companyPublicInformation'
import {setFunds} from '#state/reducers/funds'
import {setFundBuyOrders} from '#state/reducers/fundBuyOrders'
import {setFundPlacements} from '#state/reducers/fundPlacements'
import {setFundSellOrders} from '#state/reducers/fundSellOrders'
import {setFundCustomers} from '#state/reducers/fundCustomers'
import {setUnresolvedTasks} from '#state/reducers/unresolvedTasks'
import {setDepositor} from '#state/reducers/depositor'
import {setDefaultFundPlatformFees} from '#state/reducers/defaultFundPlatformFees'
import {removeUiSetting, setUiSetting, setUiSettings} from '#state/reducers/uiSettings'

export function handleWebsocketMessage(dispatch, getState, message, syncSessionState) {

    const {type, payload} = message

    switch (type) {

        case 'sessionUpdated': {
            dispatch(syncSessionState())
            break
        }

        case 'commandProcessed': {
            resolveCommand(payload)
            dispatch(updateCommandId(payload))
            handleCommandProcessed(payload)
            break
        }

        case 'pong': {
            dispatch(clearAllPings())
            break
        }

        case 'documents': {
            const documents = {}
            payload.forEach(document => {
                document.created = convertStringToDate(document.created)
                document.lastSignatureTime = convertStringToDate(document.lastSignatureTime)
                documents[document.id] = document
            })
            dispatch(setDocuments(documents))
            dispatch(setIsLoaded('documents'))
            break
        }

        case 'document': {
            payload.created = convertStringToDate(payload.created)
            payload.lastSignatureTime = convertStringToDate(payload.lastSignatureTime)
            dispatch(setDocument(payload))
            break
        }

        case 'documentRemoved': {
            dispatch(removeDocument(payload))
            break
        }

        case 'ads': {
            payload.forEach(ad => {
                ad.termination = convertStringToDate(ad.termination)
                ad.validity = convertStringToDate(ad.validity)
                ad.published = convertStringToDate(ad.published)
            })
            const adStatus = calculateAdStatus(payload)
            dispatch(setAdStatus(adStatus))
            dispatch(updateAds(payload))
            dispatch(setIsLoaded('ads'))
            break
        }

        case 'orders': {
            payload.forEach(order => {
                order.settlementDate = convertStringToDate(order.settlementDate)
            })
            dispatch(updateOrders(payload))
            dispatch(setIsLoaded('orders'))
            break
        }

        case 'order': {
            const order = payload
            order.settlementDate = convertStringToDate(order.settlementDate)
            dispatch(updateOrder(payload))
            break
        }

        case 'productsAndNibor': {
            dispatch(setProducts(payload.products))
            dispatch(setDefaultInterestRateBenchmark(payload.defaultInterestRateBenchmark))
            dispatch(setDefaultBenchmarkInterestRate(payload.benchmarkInterestRates[payload.defaultInterestRateBenchmark]))
            dispatch(setBenchmarkInterestRates(payload.benchmarkInterestRates))
            dispatch(setDefaultFundPlatformFees(payload.defaultFundPlatformFees))
            const bankCalendar = payload.bankCalendar.map(dateAsString => {
                const [year, month, date] = dateAsString.split(/[^0-9]/)
                return new Date(+year, +month - 1, +date).getTime()
            })
            dispatch(setBankCalendar(bankCalendar))
            break
        }

        case 'deposits': {
            const deposits = payload
            deposits.forEach(deposit => {
                deposit.created = convertStringToDate(deposit.created)
                deposit.terminationRequested = convertStringToDate(deposit.terminationRequested)
                deposit.terminationConfirmed = convertStringToDate(deposit.terminationConfirmed)
                deposit.terminationDate = convertStringToDate(deposit.terminationDate)
                if (deposit.currentPeriod) {
                    deposit.currentPeriod.startDate = convertStringToDate(deposit.currentPeriod.startDate)
                    deposit.currentPeriod.terminationDate = convertStringToDate(deposit.currentPeriod.terminationDate)
                }
                if (deposit.nextPeriod) {
                    deposit.nextPeriod.startDate = convertStringToDate(deposit.nextPeriod.startDate)
                    deposit.nextPeriod.terminationDate = convertStringToDate(deposit.nextPeriod.terminationDate)
                }
                if (deposit.expires) {
                    deposit.expires.date = convertStringToDate(deposit.expires.date)
                }
            })
            dispatch(updateDeposits(deposits))
            dispatch(setIsLoaded('deposits'))
            break
        }

        case 'banks': {
            const banks = payload
            const indexedBanks = banks.reduce((acc, cur) => {
                acc[cur.id] = cur
                return acc
            }, {})
            dispatch(setBanks(indexedBanks))
            dispatch(setIsLoaded('banks'))
            break
        }

        case 'depositorNames': {
            dispatch(setDepositorNames(payload))
            dispatch(setIsLoaded('depositorNames'))
            break
        }

        case 'depositors': {
            // TODO: Remove this totally when no one is using the depositors slice
            const depositors = payload
            depositors.forEach(depositor => {
                depositor.userInvites.forEach(userInvite => {
                    userInvite.requested = convertStringToDate(userInvite.requested)
                })
            })
            dispatch(setDepositor(depositors?.length > 0 ? depositors[0] : null))
            dispatch(setDepositors(depositors))
            dispatch(setIsLoaded('depositor'))
            dispatch(setIsLoaded('depositors'))
            break
        }

        case 'depositor': {
            const depositor = payload
            depositor.userInvites.forEach(userInvite => {
                userInvite.requested = convertStringToDate(userInvite.requested)
            })
            dispatch(setDepositor(depositor))
            dispatch(setDepositors([depositor]))
            dispatch(setIsLoaded('depositor'))
            dispatch(setIsLoaded('depositors'))
            break
        }

        case 'newDepositor': {
            dispatch(setNewDepositor(payload))
            break
        }

        case 'identificationDocument': {
            dispatch(setIdentificationDocument(payload.document))
            dispatch(setIsLoaded('identificationDocument'))
            break
        }

        case 'time': {
            const correctDate = new Date(payload)
            setCorrectDate(correctDate)
            break
        }

        case 'signatureProcess': {
            dispatch(updateSignatureProcess(payload))
            break
        }

        case 'bankDocuments': {
            const bankDocuments = payload
            if (bankDocuments) {
                bankDocuments.forEach(doc => {
                    doc.received = convertStringToDate(doc.received)
                    doc.created = convertStringToDate(doc.created)
                    doc.lastSignatureTime = convertStringToDate(doc.lastSignatureTime)
                })
            }
            dispatch(setBankDocuments(bankDocuments))
            dispatch(setIsLoaded('bankDocuments'))
            break
        }

        case 'profile': {
            dispatch(setProfile(payload))
            dispatch(setIsLoaded('profile'))
            break
        }

        case 'notificationSettings': {
            dispatch(setNotificationSettings(payload))
            dispatch(setIsLoaded('notificationSettings'))
            break
        }

        case 'interestRateChange': {
            const ircs = payload
            ircs.forEach(irc => {
                irc.changeDate = convertStringToDate(irc.changeDate)
            })
            dispatch(setInterestRateChange(ircs))
            dispatch(setIsLoaded('interestRateChange'))
            break
        }

        case 'history': {
            payload.forEach(h => {
                h.time = convertStringToDate(h.time)
            })
            dispatch(setHistory(payload as Array<ProcessHistoryDto>))
            dispatch(setIsLoaded('history'))
            break
        }

        case 'newCustomerDeclarations': {
            dispatch(setNewCustomerDeclarations(payload))
            dispatch(setIsLoaded('newCustomerDeclarations'))
            break
        }

        case 'dashboard': {
            dispatch(updateDashboard(payload))
            dispatch(setIsLoaded('dashboard'))
            break
        }

        case 'bank': {
            const bank = payload
            bank.userInvites.forEach(userInvite => {
                userInvite.requested = convertStringToDate(userInvite.requested)
            })
            dispatch(setBank(bank))
            dispatch(setIsLoaded('bank'))
            break
        }

        case 'messages': {
            dispatch(setMessages(payload))
            dispatch(setIsLoaded('messages'))
            break
        }

        case 'depositorOffers': {
            const depositorOffers = payload
            depositorOffers.forEach(offer => offer.deadline ? offer.deadline = new Date(offer.deadline) : null)
            depositorOffers.forEach(offer => offer.terminationDate ? offer.terminationDate = new Date(offer.terminationDate) : null)
            depositorOffers.forEach(offer => offer.createdAt ? offer.createdAt = new Date(offer.createdAt) : null)
            depositorOffers.forEach(offer => offer.lastUpdatedAt ? offer.lastUpdatedAt = new Date(offer.lastUpdatedAt) : null)
            depositorOffers.forEach(offer => offer.closedAt ? offer.closedAt = new Date(offer.closedAt) : null)
            depositorOffers.forEach(offer => offer.publishedAt ? offer.publishedAt = new Date(offer.publishedAt) : null)
            dispatch(setDepositorOffers(depositorOffers))
            dispatch(setIsLoaded('depositorOffers'))
            break
        }

        case 'depositMissingAccountStatements': {
            dispatch(setMissingAccountStatements(payload))
            dispatch(setIsLoaded('depositMissingAccountStatements'))
            break
        }

        case 'partner': {
            const partner = payload
            partner.userInvites.forEach(userInvite => {
                userInvite.requested = convertStringToDate(userInvite.requested)
            })
            dispatch(setPartner(partner))
            dispatch(setIsLoaded('partner'))
            break
        }

        case 'partnerNames': {
            dispatch(setPartnerNames(payload))
            dispatch(setIsLoaded('partnerNames'))
            break
        }

        case 'invoiceReport': {
            dispatch(setInvoiceReports(payload))
            dispatch(setIsLoaded('invoiceReport'))
            break
        }

        case 'depositDeviations': {
            dispatch(updateDepositDeviations(payload))
            dispatch(setIsLoaded('depositDeviations'))
            break
        }

        case 'companyPublicInformation': {
            dispatch(setCompanyPublicInformation(payload))
            dispatch(setIsLoaded('companyPublicInformation'))
            break
        }

        case 'funds': {
            dispatch(setFunds(payload))
            dispatch(setIsLoaded('funds'))
            break
        }

        case 'fundBuyOrders': {
            dispatch(setFundBuyOrders(payload))
            dispatch(setIsLoaded('fundBuyOrders'))
            break
        }

        case 'fundSellOrders': {
            dispatch(setFundSellOrders(payload))
            dispatch(setIsLoaded('fundSellOrders'))
            break
        }

        case 'fundPlacements': {
            dispatch(setFundPlacements(payload))
            dispatch(setIsLoaded('fundPlacements'))
            break
        }

        case 'fundCustomers': {
            dispatch(setFundCustomers(payload))
            dispatch(setIsLoaded('fundCustomers'))
            break
        }

        case 'unresolvedTasks': {
            dispatch(setUnresolvedTasks(payload))
            dispatch(setIsLoaded('unresolvedTasks'))
            break
        }

        case 'uisettings': {
            const settings = {}
            Object.keys(payload).forEach(key => {
                settings[key] = JSON.parse(payload[key])
            })
            dispatch(setUiSettings(settings))
            break
        }

        case 'uisetting': {
            Object.keys(payload).forEach(key => {
                if (payload[key] == null) {
                    dispatch(removeUiSetting({key}))
                } else {
                    dispatch(setUiSetting({key, value: JSON.parse(payload[key])}))
                }
            })

            break
        }
    }

}
