import {useEffect, useState} from 'react'
import {useSelector} from '#state/useSelector'
import {Buffer} from 'buffer'
import {v4 as uuidv4} from 'uuid'
import {ShoppingCartPersistentData} from '#state/types'
import {useDispatch} from 'react-redux'
import {setShoppingCart} from '#state/reducers/shoppingCart'

type ShoppingCartType = 'FUNDS' | 'DEPOSITS'

interface ShoppingCartItem {
    id: string,
    subId?: string,
    amount: number | null
}

type ShoppingCart<DtoType> = {
    shoppingCartId: string,
    items: ShoppingCartItem[],
    getObjectReferences: () => DtoType[]
    addItem: (id: string, subId: string, amount?: number | null) => void,
    updateItemSubId: (id: string, subId: string) => void,
    updateItemAmount: (id: string, amount: number | null) => void,
    removeItem: (id: string) => void,
    clearCart: () => void,
    getTotalAmount: () => number,
    getItemCount: () => number,
    getItemById: (id: string) => ShoppingCartItem | undefined,
    getItemSubId: (id: string) => string | undefined,
    toBase64: () => string,
    isLoaded: boolean
}

async function syncShoppingCartToServer(newShoppingCart: ShoppingCartPersistentData) {
    try {
        const response = await fetch(`/api/shoppingcart`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(newShoppingCart),
        })

        if (response.status === 401) {
            console.log('Did not update shopping cart, user is not authenticated')
        }

        if (!response.ok) {
            console.error('Failed to update shopping cart', 'Response status: ' + response.status)
            return
        }

        return await response.json()
    } catch (e) {
        console.error('Failed to update shopping cart', e)
    }
}


function createEmptyShoppingCart(depositorId: string, userId: string) {
    return {
        shoppingCartId: uuidv4(),
        items: [],
        depositorId,
        userId,
        lastUpdated: new Date().toISOString()
    }
}

async function fetchShoppingCart(userId: string, depositorId: string): Promise<ShoppingCartPersistentData> {
    try {
        if (!userId || !depositorId) {
            return null
        }
        const response = await fetch(`/api/shoppingcart`, {credentials: 'include'})
        if (response.status === 401) {
            return null
        }
        if (response.status === 404) {
            // Creates a fresh shopping cart if none is found
            return createEmptyShoppingCart(depositorId, userId)
        }
        if (!response.ok) {
            console.error('Failed to fetch shopping cart', 'Response status: ' + response.status)
            return
        }
        return await response.json() as ShoppingCartPersistentData
    } catch (e) {
        console.error('Failed to fetch shopping cart', e)
    }
}

/**
 * Sync the redux shopping cart with server side shopping cart.
 */
export function useShoppingCartServerSync() {
    const fundBuyOrdersLoaded = useSelector(state => state.loaded['fundBuyOrders'])
    const fundSellOrdersLoaded = useSelector(state => state.loaded['fundSellOrders'])
    const fundBuyOrders = useSelector(state => state.fundBuyOrders)
    const fundSellOrders = useSelector(state => state.fundSellOrders)
    const shoppingCart = useSelector(state => state.shoppingCart)
    const dispatch = useDispatch()
    const depositorId = useSelector(state => state.depositor?.id)
    const userId = useSelector(state => state.session?.id)
    const [serverSideLastUpdated, setServerSideLastUpdated] = useState<string>('')

    console.log('Rendering useShoppingCartServerSync')

    // Load shopping cart on startup or when userId or depositorId changes
    useEffect(() => {
        let abort = false
        ;(async () => {
            if (!depositorId || !userId || !fundBuyOrdersLoaded || !fundSellOrdersLoaded) {
                setShoppingCart(null)
                return
            }
            if (!shoppingCart || shoppingCart.depositorId !== depositorId || shoppingCart.userId !== userId) {
                const serverSideShoppingCart = await fetchShoppingCart(userId, depositorId)
                if (abort) {
                    console.log('Aborting shopping cart sync')
                    return
                }

                // If we have a serverSideShoppingCart, and the redux shoppingCart is missing or has a different depositorId
                if (serverSideShoppingCart && (!shoppingCart || shoppingCart.depositorId !== serverSideShoppingCart.depositorId)) {

                    const shoppingCartIsAlreadyUsed = !!serverSideShoppingCart &&
                        (fundBuyOrders.find(o => o.orderGroupId === serverSideShoppingCart.shoppingCartId) ||
                            fundSellOrders.find(o => o.orderGroupId === serverSideShoppingCart.shoppingCartId))

                    if (shoppingCartIsAlreadyUsed) {
                        console.warn('Shopping cart is already used, creating a new one')
                        const emptyShoppingCart = createEmptyShoppingCart(depositorId, userId)
                        setServerSideLastUpdated(emptyShoppingCart.lastUpdated)
                        dispatch(setShoppingCart(emptyShoppingCart))
                    } else {
                        setServerSideLastUpdated(serverSideShoppingCart.lastUpdated)
                        dispatch(setShoppingCart(serverSideShoppingCart))
                    }
                }
            }
        })()

        return () => {
            abort = true
        }
    }, [dispatch, shoppingCart, fundBuyOrders, fundSellOrders, depositorId, userId, fundBuyOrdersLoaded, fundSellOrdersLoaded])

    // Store shopping cart to server on each change
    useEffect(() => {
        // Wait until the shopping cart is loaded from server
        if (shoppingCart /*&& serverSideLastUpdated !== shoppingCart.lastUpdated*/) {
            try {
                syncShoppingCartToServer(shoppingCart)
            } catch (e) {
                try {
                    syncShoppingCartToServer(shoppingCart)
                } catch (e) {
                    console.error('Failed to sync shopping cart to server', e)
                }
            }
        }
    }, [shoppingCart, serverSideLastUpdated])

}

/**
 * Uses the shoppingCart state from redux store and provides functions to manipulate it.
 */
function useShoppingCart<DtoType extends { id: string }>(type: ShoppingCartType): ShoppingCart<DtoType> {
    const shoppingCart = useSelector(state => state.shoppingCart)
    const shoppingCartId = shoppingCart?.shoppingCartId
    const items = shoppingCart?.items ?? []
    const dispatch = useDispatch()

    const availableItems = useSelector(state => {
        if (type === 'FUNDS' && state.loaded.funds) {
            return state.funds as unknown as DtoType[]
        }
        if (type === 'DEPOSITS' && state.loaded.deposits) {
            return state.deposits as unknown as DtoType[]
        }
        return null
    })

    function updateShoppingCartItems(items: ShoppingCartItem[]) {
        // Silently ignore updates before the shopping cart is loaded from server
        if (shoppingCart) {
            dispatch(setShoppingCart({...shoppingCart, items, lastUpdated: new Date().toISOString()}))
        } else {
            console.warn('Shopping cart not loaded yet, ignoring update')
        }
    }

    /*
    useEffect(() => {
        if (availableItems !== null && items.find(item => !availableItems.map(ai => ai.id).includes(item.id))) {
            updateShoppingCart({
                shoppingCartId,
                items: items.filter(item => availableItems.map(ai => ai.id).includes(item.id))
            })
        }
    }, [availableItems, items, updateShoppingCart, shoppingCartId])
     */

    function getObjectReferences(): DtoType[] {
        if (availableItems === null) {
            return []
        }
        return availableItems.filter(ai => items.find(item => item.id === ai.id))
    }

    function addItem(id: string, subId: string, amount?: number) {
        updateShoppingCartItems([...items.filter(i => i.id !== id), {id, subId, amount}])
    }

    function updateItemSubId(id: string, subId: string) {
        updateShoppingCartItems(items.map(i => i.id === id ? {...i, subId} : i))
    }

    function updateItemAmount(id: string, amount: number | null) {
        updateShoppingCartItems(items.map(i => i.id === id ? {...i, amount} : i))
    }

    function removeItem(id: string) {
        updateShoppingCartItems(items.filter(items => items.id !== id))
    }

    function clearCart() {
        updateShoppingCartItems([])
    }

    function getTotalAmount() {
        return items.reduce((total, item) => total + (item.amount ?? 0), 0)
    }

    function getItemCount() {
        return items.length
    }

    function getItemById(id: string) {
        return items.find(i => i.id === id)
    }

    function getItemSubId(id: string) {
        return items.find(i => i.id === id)?.subId
    }

    function toBase64(): string {
        return Buffer.from(JSON.stringify(items), 'utf8').toString('base64')
    }

    return ({
        shoppingCartId,
        items,
        getObjectReferences,
        addItem,
        updateItemAmount,
        removeItem,
        clearCart,
        getTotalAmount,
        getItemCount,
        getItemById,
        toBase64,
        updateItemSubId,
        getItemSubId,
        isLoaded: !!shoppingCart
    })
}

export default useShoppingCart

