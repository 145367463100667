import { useSelector } from "#app/state/useSelector"
import { Currency } from '@fixrate/fixrate-query'

type CurrencyFormatterOptions = {
    withCurrency?: boolean,
    currency?: string | null,
    minimumDecimals?: number,
    maximumDecimals?: number,
    locale?: string
}

type CurrencyOutputOptions = {
    withCurrency?: boolean,
    decimals?: number
    decimalRange?: {
        minimum: number | undefined
        maximum: number | undefined
    },
    millionFormat?: boolean,
}

export function createCurrencyFormatter({withCurrency = true,  minimumDecimals = 2, maximumDecimals = 2, currency = 'NOK', locale = 'nb-NO'} : CurrencyFormatterOptions) {
    return new Intl.NumberFormat(locale, {
        style: currency && withCurrency ? 'currency' : undefined,
        currency: currency && withCurrency ? currency : undefined,
        minimumFractionDigits: minimumDecimals,
        maximumFractionDigits: maximumDecimals,
    })
}

export const useCurrencyOutput = () => {
    const language = useSelector(state => state?.session?.language)
    const currency: Currency = useSelector(state => state?.session?.association?.currentCurrency) || 'NOK'

    const getLanguageCode = () => {
        switch (language) {
            case 'en':
                return 'en-GB'
            case 'sv':
                return 'sv-SE'
            default:
                return 'nb-NO'
        }
    }

    return (value: number, options?: CurrencyOutputOptions) : string | null => {
        const defaultOptions : CurrencyOutputOptions = {
            withCurrency: true,
            decimals: 2,
            decimalRange: {
                minimum: undefined,
                maximum: undefined
            },
            millionFormat: false
        }

        const mergedOptions = { ...defaultOptions, ...options }
        const gotDecimalRange = options?.decimalRange?.minimum !== undefined && options?.decimalRange?.maximum !== undefined

        if (!value && value !== 0) {
            return null
        }

        const cappedValue = value < 0 ? Math.ceil(value / 1000000) : Math.floor(value / 1000000)
        const currencyFormatter = createCurrencyFormatter({
            currency: currency,
            withCurrency: mergedOptions.withCurrency,
            minimumDecimals: gotDecimalRange ? mergedOptions.decimalRange.minimum : mergedOptions.decimals,
            maximumDecimals: gotDecimalRange ? mergedOptions.decimalRange.maximum :  mergedOptions.decimals,
            locale: getLanguageCode()
        })
        return currencyFormatter.format(mergedOptions.millionFormat ? cappedValue : value)
    }
}

export const useCurrencyOutputWithCurrency = (currency: string) => {
    return (value: number, options?: CurrencyOutputOptions) : string | null => {
        const defaultOptions : CurrencyOutputOptions = {
            withCurrency: true,
            decimals: 2,
            decimalRange: {
                minimum: undefined,
                maximum: undefined
            },
            millionFormat: false
        }

        const mergedOptions = { ...defaultOptions, ...options }
        const gotDecimalRange = options?.decimalRange?.minimum !== undefined && options?.decimalRange?.maximum !== undefined

        if (!value && value !== 0) {
            return null
        }

        const cappedValue = value < 0 ? Math.ceil(value / 1000000) : Math.floor(value / 1000000)
        const currencyFormatter = createCurrencyFormatter({
            currency: currency,
            withCurrency: mergedOptions.withCurrency,
            minimumDecimals: gotDecimalRange ? mergedOptions.decimalRange.minimum : mergedOptions.decimals,
            maximumDecimals: gotDecimalRange ? mergedOptions.decimalRange.maximum :  mergedOptions.decimals
        })
        return currencyFormatter.format(mergedOptions.millionFormat ? cappedValue : value)
    }
}
