import {createSlice} from '@reduxjs/toolkit'

const slice = createSlice({
    name: 'notificationSettings',
    initialState: {},
    reducers: {
        setNotificationSettings(state, action) {
            return action.payload
        }
    }
})

const {actions, reducer} = slice
export const {setNotificationSettings} = actions
export default reducer