import {createSlice} from '@reduxjs/toolkit'

const slice = createSlice({
    name: 'fundSellOrders',
    initialState: [],
    reducers: {
        setFundSellOrders(state, action) {
            return action.payload
        },
    },
})

const {actions, reducer} = slice
export const {setFundSellOrders} = actions
export default reducer