type Props = {
    color: string
}
export default function LegendSquare({color}: Props) {
    return (
        <div style={{
            backgroundColor: color,
            marginTop: 2,
            width: '0.7em',
            height: '0.7em',
            aspectRatio: '1/1',
            printColorAdjust: 'exact',
            WebkitPrintColorAdjust: 'exact'
        }}/>
    )
}
