import { Children } from 'react';

function True({children}) {
    return children
}

function False({children}) {
    return children
}

/**
 * /**
 * @deprecated Use statements inside {} instead. E.g { condition && <div>...</div> }
*/

function If({condition, children}) {

    let trueOrFalse
    Children.forEach(children, child => {
        if (child?.type === If.True && condition) {
            trueOrFalse = child
        }
        if (child?.type === If.False && !condition) {
            trueOrFalse = child
        }
    })
    if (trueOrFalse) {
        return trueOrFalse
    }

    if (condition) {
        return children
    } else {
        return null
    }
}

If.True = True
If.False = False

export default If