import { COMPANY_COLORS, PURPLE } from '#app/colors/colors'
import { getSupportArticleIdForPathname } from '#app/layers/SupportPane/getSupportArticleIdForPathname'
import AddNewOrganization from '#app/pages/organizations/AddNewOrganization/AddNewOrganization'
import { switchOrganisation } from '#app/services/thunks/session'
import useCurrentDepositor from '#app/services/useCurrentDepositor'
import { AssociationDto } from '@fixrate/fixrate-security'
import { isFixrateUser } from '#app/state/selectors'
import { useSelector } from '#app/state/useSelector'
import useSupportPane from '#services/useSupportPane'
import { useTracking } from '#services/useTracking'
import { OrganisationType } from '@fixrate/fixrate-query'
import { Box, Button, Stack, Typography, useMediaQuery } from '@mui/material'
import classNames from 'classnames'
import * as React from 'react'
import { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { BankCurrencySelection } from './components/CurrencySelection/BankCurrencySelection'
import { DepositorCurrencySelection } from './components/CurrencySelection/DepositorCurrencySelection'
import { selectButtonStyles } from './components/MenuComponents'
import { OrganizationSelection } from './components/OrganizationSelection'
import { PartnerSelectCustomer } from './components/PartnerSelectCustomer'
import { PartnerSelectionMenu } from './components/PartnerSelectionMenu'
import { ProfileMenu } from './components/ProfileMenu'
import styles from './PageHeader.module.scss'
import { NewBankAdButton } from './NewBankAdButton'
import config from "#app/config"

const ORGANISATION_TYPE_SORT_ORDER: OrganisationType[] = ['FIXRATE', 'FUND_INTERMEDIARY', 'PARTNER', 'BANK', 'DEPOSITOR']
export type OrganisationSelectorOption = {
    key: string
    organisationType: OrganisationType
    name: string
    iconColor: string
    iconName: string[]
    organisationId: string
    selected: boolean
}

export type MenuLink = {
    icon: string,
    text: string,
    link: string,
    visible?: boolean,
    warning?: boolean,
    adminOnly?: boolean,
    dataCy?: string,
}

export function getIconText(name: string) {
    if (!name) {
        return null
    }

    return name
        .replace(' & ', ' ')
        .replace(/ AS$/, '')
        .replace('A/S', '')
        .split(' ')
        .map((i, index) => {
            if (index < 2) {
                return i.charAt(0)
            }
            return ''
        });
}

type Props = {
    title?: string | ReactElement
    subtitle?: string
    backToLink?: string
    useBackButton?: boolean
    children?: React.ReactNode
    shadow?: boolean
    icon?: string
}

export default function PageHeader({title, backToLink, subtitle, useBackButton = false, children, shadow = true, icon}: Props) {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    const supportPane = useSupportPane()
    const {trackEvent} = useTracking({page: 'SupportPane'})
    const sessionId = useSelector(state => state.session?.id)
    const fixrateUser = useSelector(state => isFixrateUser(state))
    const authenticated = useSelector(state => state.session.authenticated)
    const association = useSelector(state => state.session?.association)
    const associations = useSelector(state => state.session?.associations)
    const [registerOrganizationVisible, setRegisterOrganizationVisible] = useState(false)
    const organisationSelectorOptions = getOrganisationSelectorOptions(associations, association)
    const depositor = useCurrentDepositor()
    const isMobile = useMediaQuery('(max-width: 800px)')

    const selectedOrganisation = organisationSelectorOptions?.find(o => o.selected)
    const partnerSession = selectedOrganisation?.organisationType === 'PARTNER'
    const bankSession = selectedOrganisation?.organisationType === 'BANK'
    const depositorSession = selectedOrganisation?.organisationType === 'DEPOSITOR'
    const isPartnerAtDepositor = depositor?.users?.find(u => u.id === sessionId)?.roles?.find(r => r === 'DEPOSITOR_PARTNER_ACCOUNTANT' || r === 'DEPOSITOR_PARTNER_SUPPORT' || r === 'DEPOSITOR_PARTNER_ADVISOR')

    function navigateToPartner() {
        const orgId = organisationSelectorOptions.find(o => o.organisationType === 'PARTNER')?.organisationId
        dispatch(switchOrganisation('PARTNER', orgId, () => navigate('/customer')))
    }

    const openHelpMenu = () => {
        trackEvent({event: 'open'})
        const relevantArticleId = getSupportArticleIdForPathname(location.pathname)
        if (relevantArticleId) {
            supportPane.show(relevantArticleId)
        } else {
            supportPane.showTopLevelPage()
        }
    }

    function backButtonClick() {
        if (!backToLink || useBackButton) {
            navigate(-1)
        } else {
            navigate(backToLink)
        }
    }

    const titleIcon = icon && (
        <i className={classNames(icon, styles.titleIcon)}/>
    )

    const titleText = !!title && (
        <h2 className={classNames(styles.title)}>{titleIcon}<span>{title}</span></h2>
    )

    const backButton = (!!backToLink || useBackButton) && (
        <i onClick={backButtonClick} className={classNames('ri-arrow-left-line', styles.backIcon)} data-cy="backLink"/>
    )

    return (
        <header className={classNames(styles.appBar, shadow ? '' : styles.removeShadow)}>
            <div id='page-header' className={styles.headerElement}>
                <div className={styles.titleWrapper}>
                    {backButton}
                    <div className={classNames(styles.titleElement, { [styles.titleWithoutBackButton]: !backButton })}>
                        {titleText}
                        {subtitle ? <Typography variant="caption" pl={{md: 1}}>{subtitle}</Typography> : null}
                    </div>
                </div>
                {children ? children : null}
                <div className={styles.helpOrgWrapper}>
                    {authenticated && (
                        <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                            {isPartnerAtDepositor && (
                                <Button onClick={navigateToPartner} size='small' sx={{ mr: 1 }} variant={'outlined'} startIcon={<i className='ri-logout-box-line' />}>
                                    {t('common.goToPartnerPortal')}
                                </Button>
                            )}
                            {!partnerSession && organisationSelectorOptions.length === 0 && (
                                <Button onClick={() => setRegisterOrganizationVisible(true)} variant={'contained'} sx={{ fontSize: '1.4rem' }} startIcon={<i className={'ri-add-circle-line'} />}>
                                    {t('common.registerCompany')}
                                </Button>
                            )}
                            <AddNewOrganization visible={registerOrganizationVisible} setShowNewOrganization={() => setRegisterOrganizationVisible(false)} />
                            {bankSession && !isMobile && <NewBankAdButton/>}
                            {partnerSession && (
                                <React.Fragment>
                                    <PartnerSelectCustomer organisationOptions={organisationSelectorOptions} />
                                    <PartnerSelectionMenu selectedOrganisation={selectedOrganisation} organisationOptions={organisationSelectorOptions} />
                                </React.Fragment>
                            )}
                            {!partnerSession && <OrganizationSelection selectedOrganisation={selectedOrganisation} organisationOptions={organisationSelectorOptions} />}
                            {bankSession && <BankCurrencySelection />}
                            {depositorSession && <DepositorCurrencySelection />}
                            <ProfileMenu />
                        </Box>
                    )}
                    <Stack direction={'row'}>
                        <Button
                            color='secondary'
                            sx={{ ...selectButtonStyles, minWidth: 'auto' }}
                            onClick={openHelpMenu}
                            startIcon={<i style={{ fontSize: '1.8rem', color: PURPLE[500] }} className='ri-lifebuoy-line' />}
                        >
                            {!isMobile && t('common.help')}
                        </Button>
                        {fixrateUser && (
                            <Button
                                color='secondary'
                                sx={{ ...selectButtonStyles, minWidth: 'auto' }}
                                onClick={() => window.location.replace(config().homePageUrl)}
                                startIcon={<i style={{ fontSize: '1.8rem', color: PURPLE[500] }} className='ri-global-line' />}
                            >
                                {!isMobile && t('common.homepage')}
                            </Button>
                        )}
                    </Stack>
                </div>
            </div>
        </header>
    )
}

export function getOrganisationSelectorOptions(associations: AssociationDto[], association: AssociationDto): OrganisationSelectorOption[] {
    const allAssociations = associations ?? []
    return allAssociations.slice().sort((a, b) => {
        const organisationSortOrder = ORGANISATION_TYPE_SORT_ORDER.indexOf(a?.organisationType) - ORGANISATION_TYPE_SORT_ORDER.indexOf(b?.organisationType)
        if (organisationSortOrder === 0) {
            return a.name.localeCompare(b.name)
        } else {
            return organisationSortOrder
        }
    }).map((a, index): OrganisationSelectorOption => ({
        key: a.organisationType + ':' + a.organisation?.id,
        organisationType: a.organisationType,
        name: a.name,
        iconName: getIconText(a.name),
        organisationId: a.organisation?.id,
        selected: a.organisation.id === association?.organisation.id,
        iconColor: COMPANY_COLORS[index],
    }))
}

