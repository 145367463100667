import { useEndpoint } from '#app/services/beta';
import { sendExceptionToSentry } from '#app/services/sentry';
import useCurrentDepositor from '#app/services/useCurrentDepositor';
import usePortfolio from '#app/services/usePortfolio';
import {Currency, FundDto} from '@fixrate/fixrate-query';
import { CurrencySelection } from './CurrencySelection';
import useShoppingCart from "#services/useShoppingCart";

export const DepositorCurrencySelection = () => {
    const currentDepositor = useCurrentDepositor()
    const portfolio = usePortfolio()
    const { setPortfolioDepositorSession } = useEndpoint()
    const fundShoppingCart = useShoppingCart<FundDto>('FUNDS')

    const portfolios = currentDepositor?.portfolios
    const currencies = portfolios?.map(p => p.currency)

    const switchCurrency = (currency: Currency) => {
        const selectedPortfolio = portfolios?.find(p => p.currency === currency)
        fundShoppingCart.clearCart()
        setPortfolioDepositorSession(selectedPortfolio.id).catch((err) => {
            sendExceptionToSentry(err, {currency: currency, portfolio: selectedPortfolio})
        })
    }

    return currencies ? (
        <CurrencySelection currencies={currencies} selectedCurrency={portfolio?.currency} switchCurrency={switchCurrency} />
    ) : null
}
