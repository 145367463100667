import * as i18next from 'react-i18next'

/**
 * @deprecated
 * @param {string} prefix
 * @return {{t: (function(...*): string), i18n: i18n}}
 */
const useTranslation = (prefix = null) => {
    const {t, i18n} = i18next.useTranslation()
    const tMod = prefix ? (key, opts) => t(key.includes('.') ? key : prefix + '.' + key, opts) : t
    return {t: tMod, i18n}
}

export default useTranslation
