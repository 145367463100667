/*

    Navigates to root and reloads application

 */
const navigateToCalculatedRoot = () => {
    if (window.location.host === 'market.fixrate.no') {
        document.location.assign('https://fixrate.no')
    } else {
        document.location.assign('/')
    }
}

export { navigateToCalculatedRoot }
