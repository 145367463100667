import {createSlice} from '@reduxjs/toolkit'
import update from 'immutability-helper';

const DEFAULT_STATE = {
    activeFilter: {
        products: {},
        minDepositAmount: 0,
        totalAssets: 0,
        minInterestRate: undefined,
        minInterestRateMargin: undefined
    },
}


const slice = createSlice({
    name: 'marketplacefilter',
    initialState: DEFAULT_STATE,
    reducers: {
        setFilterProduct(state, action) {
            console.log('setFilterProduct', action.payload)
            const id = action.payload.id;
            const newValue = action.payload.state;
            return update(state, {activeFilter: {products: {[id]: {$set: newValue}}}});
        },
        setFilterTotalAssets(state, action) {
            return update(state, {activeFilter: {totalAssets: {$set: action.payload}}});
        },
        setFilterMinDepositAmount(state, action) {
            return update(state, {activeFilter: {minDepositAmount: {$set: action.payload}}});
        },
        setFilterMinInterestRate(state, action) {
            return update(state, {activeFilter: {minInterestRate: {$set: action.payload}}});
        },
        setFilterMinInterestRateMargin(state, action) {
            return update(state, {activeFilter: {minInterestRateMargin: {$set: action.payload}}});
        },
    },
})

const {actions, reducer} = slice
export const {
    setFilterProduct,
    setFilterTotalAssets,
    setFilterMinDepositAmount,
    setFilterMinInterestRate,
    setFilterMinInterestRateMargin,
} = actions
export default reducer
