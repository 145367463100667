import {v4 as uuidv4} from 'uuid'
import * as infoMessages from '#state/reducers/infoMessages'
import {InfoMessageType} from '#state/types'


export const addSelfRemovingInfoMessage = (type: InfoMessageType, message: string, detail?:string, technicalDetail?:string) => (dispatch) => {
    const id = uuidv4()
    dispatch(infoMessages.addInfoMessage({type, message, detail, technicalDetail, id}))
    setTimeout(() => {
        dispatch(infoMessages.removeInfoMessage(id))
    }, 8000)
}

export const addInfoMessage = (type: InfoMessageType, message: string, detail?: string, technicalDetail?:string) => (dispatch) => {
    const id = uuidv4()
    dispatch(infoMessages.addInfoMessage({type, message, detail, technicalDetail, id}))
}

export const clearInfoMessages = (dispatch) => {
    dispatch(infoMessages.clearInfoMessages({}))
}
