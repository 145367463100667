import * as React from 'react';
import ReactDatePicker, {registerLocale} from 'react-datepicker'
import DummyDatePicker from './DummyDatePicker'
import nb from 'date-fns/locale/nb'
import './react-datepicker.css'
import DateOutput from '#components/DateOutput'
import {PatternFormat} from 'react-number-format'
import {useTranslation} from 'react-i18next'

registerLocale('nb', nb)

interface Props {
    id?: string,
    inline?: boolean,
    className?: string,
    selected?: Date | null,
    onChange: (Date) => void,
    onBlur?: (Date) => void,
    openToDate?: Date,
    showTimeSelect?: boolean,
    disabled?: boolean,
    filterDate?: (Date) => boolean,
    minDate?: Date,
    maxDate?: Date,
    portalId?: string,
    customInput?: React.ReactNode,
    'data-cy'?: string
}

export default function DatePicker({id, 'data-cy': dataCy, inline = false, className, selected, onChange, onBlur, openToDate, showTimeSelect, disabled, filterDate, minDate, maxDate, portalId = "datepicker-portal", customInput=null} : Props) {

    const {t, i18n} = useTranslation()

    // NB! Test mode is necessary for the Percy visual tests to work
    const testMode = document.cookie.includes('_fixrate_stop_time')

    const dateFormatString = showTimeSelect
        ? DateOutput.dateTimeFormat
        : DateOutput.dateFormat

    const autoCompleteBlocker = (field) => {
        const randomNumber = Math.floor(Math.random() * (1 - 1000 + 1)) + 1;
        return field + "-" + randomNumber
    }

    return testMode ? (
        <DummyDatePicker id={id} onChange={onChange} data-cy={dataCy}/>
    ) : (
        <ReactDatePicker
            data-cy={dataCy}
            style={{display: 'none'}}
            id={id}
            className={className}
            inline={inline}
            customInput={ customInput ? customInput : (
                <PatternFormat
                    format={'##.##.####'}
                    type={'text'}
                    mask={'_'}
                />
            )}
            selected={selected}
            onChange={onChange}
            onBlur={onBlur}
            openToDate={openToDate}
            showWeekNumbers
            showTimeSelect={showTimeSelect}
            dateFormat={testMode ? DateOutput.formatDate(new Date()) : dateFormatString}
            disabled={disabled}
            showYearDropdown
            filterDate={filterDate}
            minDate={minDate}
            maxDate={maxDate}
            dropdownMode={'scroll'}
            timeFormat={DateOutput.timeFormat}
            timeCaption={t('components-DatePicker.time')}
            locale={i18n.language}
            portalId={portalId}
            name={autoCompleteBlocker("birthDate")}
            autoComplete="off"
        />
    )
}

