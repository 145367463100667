import {createSlice} from '@reduxjs/toolkit'

const DEFAULT_STATE = {
    showModal: false,
    title: '',
    text: '',
    cancelButtonText: '',
    submitButtonText: '',
    cancelAction: () => 0,
    submitAction: () => 0,
}

const slice = createSlice({
    name: 'confirmModal',
    initialState: DEFAULT_STATE,
    reducers: {
        showConfirmModal(state, action) {
            return ({
                ...DEFAULT_STATE,
                ...action.payload,
                showModal: true,
            })
        },
        closeConfirmModal(state, action) {
            return DEFAULT_STATE
        },
    },
})

const {actions, reducer} = slice
export const {showConfirmModal, closeConfirmModal} = actions
export default reducer
