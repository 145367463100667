import {createSlice} from '@reduxjs/toolkit'

const slice = createSlice({
    name: 'fundPlacements',
    initialState: [],
    reducers: {
        setFundPlacements(state, action) {
            return action.payload
        },
    },
})

const {actions, reducer} = slice
export const {setFundPlacements} = actions
export default reducer