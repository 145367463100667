import { useRef } from 'react';
import {Chart} from 'chart.js'
import {FundAccountingReport} from '@fixrate/fixrate-report'
import {BURNT_ORANGE, FOREST_GREEN, SILVER_GRAY, SPRING_GREEN} from '#app/colors/colors'
import {useTranslation} from 'react-i18next'
import { useCurrencyOutputWithCurrency} from '#app/components/CurrencyOutput/useCurrencyOutput';
import {Currency} from "@fixrate/fixrate-query";

// [backgound color, font color]
const VALUE_COLORS = [FOREST_GREEN[500], FOREST_GREEN[50]]
const GAIN_COLORS = [SPRING_GREEN[600], SPRING_GREEN[50]]
const LOSS_COLORS = [BURNT_ORANGE[500], BURNT_ORANGE[50]]

type BarChart = Chart<'bar', number[][]>
type Props = {
    report: FundAccountingReport
    currency: Currency
    pdfMode?: boolean
}

/*
 For some reason using useMediaQuery('print') in this component makes the chart not render in PDF.
 Hence, we pass pdfMode as a prop.
 */
export default function BalanceDevelopmentChart({report, currency, pdfMode=false}: Props) {
    const {t} = useTranslation()
    const chartRef = useRef<BarChart | null>(null)
    const Currency = useCurrencyOutputWithCurrency(currency)

    const purchaseValue = report.balanceReport.entries.MONEY_MARKET_FUND_NORWAY.PURCHASE_VALUE.outgoing
    const purchasedInterestValue = report.balanceReport.entries.MONEY_MARKET_FUND_NORWAY.PURCHASED_INTEREST_VALUE.outgoing
    const accruedInterestValue = report.balanceReport.entries.MONEY_MARKET_FUND_NORWAY.ACCRUED_INTEREST_VALUE.outgoing
    const valueChange = report.balanceReport.entries.MONEY_MARKET_FUND_NORWAY.UNREALIZED_NET_VALUE_GAIN.outgoing
    const finalBalance = report.balanceReport.sumOutgoing

    const minValue = Math.min(purchaseValue, finalBalance) // Make sure all columns are visible
    const suggestedMinCap = minValue * .9 // Show upper 10% of the large columns
    const suggestedStepSize = 10**(Math.max(0, `${Math.floor(minValue)}`.length - 2)) // Find a step size that is a multiple of 10 that fits the number of digits
    const minCap = suggestedMinCap + suggestedStepSize - (suggestedMinCap % suggestedStepSize) // Round up to the next step

    // [visible start, visible end, absoulute value]
    const data = [
        [minCap, purchaseValue, purchaseValue],
        [purchaseValue, (purchaseValue + purchasedInterestValue), purchasedInterestValue],
        [(purchaseValue + purchasedInterestValue), (purchaseValue + purchasedInterestValue + accruedInterestValue), accruedInterestValue],
        [(purchaseValue + purchasedInterestValue + accruedInterestValue), (purchaseValue + purchasedInterestValue + accruedInterestValue + valueChange), valueChange],
        [minCap, finalBalance, finalBalance],
    ]

    const labels = [
        t('pages-fund-reports-balance-development-chart.labelPurchasedUnits'),
        t('pages-fund-reports-balance-development-chart.labelPurchasedInterestValue'),
        t('pages-fund-reports-balance-development-chart.labelAccruedInterestValue'),
        t('pages-fund-reports-balance-development-chart.labelValueChange'),
        t('pages-fund-reports-balance-development-chart.labelFinalBalance')]
    const colors = [VALUE_COLORS[0], VALUE_COLORS[0], GAIN_COLORS[0], valueChange > 0 ? GAIN_COLORS[0] : LOSS_COLORS[0], VALUE_COLORS[0]]
    const fontColors = [VALUE_COLORS[1], VALUE_COLORS[1], GAIN_COLORS[1], valueChange > 0 ? GAIN_COLORS[1] : LOSS_COLORS[1], VALUE_COLORS[1]]

    const canvasCallback = (canvas: HTMLCanvasElement | null) => {
        const ctx = canvas?.getContext('2d')
        if (ctx && !chartRef.current) {
            chartRef.current = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: labels,
                    datasets: [
                        {
                            label: t('pages-fund-reports-balance-development-chart.labelPortfolio'),
                            data: data,
                            backgroundColor: colors,
                            borderColor: 'transparent',
                        },
                    ],
                },
                options: {
                    color: SILVER_GRAY[500],
                    aspectRatio: (5 / 2),
                    maintainAspectRatio: !pdfMode,
                    responsive: true,
                    indexAxis: 'x',
                    animation: pdfMode ? false : {
                        duration: 500
                    },
                    scales: {
                        y: {
                            display: true,
                            type: 'linear',
                            grace: '5%',
                            beginAtZero: false,
                            min: minCap,
                            ticks: {
                                font: {
                                    size: 10,
                                    family: "'Montserrat'",
                                    weight: '500',
                                },
                                color: SILVER_GRAY[500],
                                callback: (value) => Currency(typeof value === 'string' ? parseFloat(value) : value, { decimals: 0 }),
                            },
                            grid: {
                                drawBorder: false,
                                borderDash: [4, 4],
                                color: '#00000025',
                            },
                        },
                        x: {
                            type: 'category',
                            ticks: {
                                display: true,
                                font: {
                                    size: 10,
                                    family: "'Montserrat'",
                                    weight: '500',
                                },
                                color: SILVER_GRAY[500],
                                callback: (value, i) => labels[i],
                            },
                            grid: {
                                drawBorder: false,
                                display: false,
                            },
                        },
                    },
                    hover: {
                        mode: null,
                    },
                    plugins: {
                        legend: {
                            display: false,
                        },
                        tooltip: {
                            enabled: false,
                        },
                        datalabels: {
                            display: true,
                            color: fontColors,
                            backgroundColor: colors,
                            formatter: (value: number[]) => Currency(value[2], { decimals: 0 }),
                        }
                    },
                },
            })
        }
    }

    const webStyle = {
        maxWidth: '100%'
    }
    const pdfStyle = {
        height: '300px',
        width: '250px'
    }

    return (
        <canvas ref={canvasCallback} style={pdfMode ? pdfStyle : webStyle} id={'balance-development-chart-canvas'}/>
    )
}
