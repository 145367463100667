import {createSlice} from '@reduxjs/toolkit'

const slice = createSlice({
    name: 'interestRateBenchmarks',
    initialState: {
        defaultInterestRateBenchmark: null,
        defaultBenchmarkInterestRate: null,
        benchmarkInterestRates: {}
    },
    reducers: {
        setDefaultInterestRateBenchmark(state, action) {
            state.defaultInterestRateBenchmark = action.payload
        },
        setDefaultBenchmarkInterestRate(state, action) {
            state.defaultBenchmarkInterestRate = action.payload
        },
        setBenchmarkInterestRates(state, action) {
            state.benchmarkInterestRates = action.payload
        },
    },
})

const {actions, reducer} = slice
export const {setDefaultInterestRateBenchmark, setDefaultBenchmarkInterestRate, setBenchmarkInterestRates} = actions
export default reducer
