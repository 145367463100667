/*

    Functions that validates a value according to special rules

*/

import { validateSwedishAccountNumber } from '#app/pages/organizations/OrganizationDetail/Accounts/swedishAccountValidator'
import { CountryCode } from '@fixrate/fixrate-query'
import IBAN from 'iban'
import Personnummer from 'personnummer'

function validateNorwegianNationalIdentity(nationalIdentity: string) {
    const birthNumber = nationalIdentity.toString()

    if (!birthNumber || birthNumber.length !== 11) {
        return false
    }

    const _sum = (birthNumber, factors) => {
        let sum = 0
        for (let i = 0, l = factors.length; i < l; ++i) {
            sum += parseInt(birthNumber.charAt(i), 10) * factors[i]
        }
        return sum
    }

    let checksum1 = 11 - (_sum(birthNumber, [3, 7, 6, 1, 8, 9, 4, 5, 2]) % 11)
    if (checksum1 === 11) checksum1 = 0
    let checksum2 = 11 - (_sum(birthNumber, [5, 4, 3, 2, 7, 6, 5, 4, 3, 2]) % 11)
    if (checksum2 === 11) checksum2 = 0
    return checksum1 === parseInt(birthNumber.charAt(9), 10)
        && checksum2 === parseInt(birthNumber.charAt(10), 10)
}

export function validateNationalIdentity(nationality: string, nationalIdentity: string) {
    if (nationality?.toUpperCase() === 'SE') {
        return Personnummer.valid(nationalIdentity)
    }
    if (nationality?.toUpperCase() !== 'NO') {
        return true
    }
    return validateNorwegianNationalIdentity(nationalIdentity)
}

const mod11OfNumberWithControlDigit = function (input: string) {
    let controlNumber = 2, sumForMod = 0, i

    for (i = input.length - 2; i >= 0; --i) {
        sumForMod += +input.charAt(i) * controlNumber
        if (++controlNumber > 7) controlNumber = 2
    }
    const result = (11 - sumForMod % 11)
    return result === 11 ? 0 : result
}

export const validateAccountNumber = function (accountNumber: string, countryCode: CountryCode) {
    if (!accountNumber) return false
    accountNumber = accountNumber.toString().replace(/[.\s]/g, '')

    if (countryCode === 'NO') {
        if (accountNumber.length !== 11) return false
        return parseInt(accountNumber.charAt(accountNumber.length - 1), 10) === mod11OfNumberWithControlDigit(accountNumber)
    }

    if (countryCode === 'SE') {
        return validateSwedishAccountNumber(accountNumber?.replace("-",""))
    }
}

export const validateIbanNumber = function (ibanNumber: string) {
    if (!ibanNumber) return false
    ibanNumber = ibanNumber.toString().replace(/\./g, '')
    return IBAN.isValid(ibanNumber)
}

export const validateEmailAddress = function (emailAddress: string) {
    if (!emailAddress) return false
    return !!emailAddress.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i)
}

export const validatePhoneNumber = function (phoneNumber: string) {
    if (!phoneNumber) return false
    return !!phoneNumber.match(/^\+?[0-9]*$/)
}

export const validatePinCode = (postcode: string) => {
    if (!postcode) return false
    return !!postcode.match(/^\d{4,8}$/)
}

export const validateNorwegianPostcode = (postcode: string) => {
    if (!postcode) return false
    return !!postcode.match(/^\d{4}$/)
}

export const validateSwedishPostcode = (postcode: string) => {
    if (!postcode) return false
    return !!postcode.match(/^\d{3}\s?\d{2}$/)
}

export const isSequence = (num: string, step: number) => {
    let ret = true
    const arr = num.toString().split('')
    arr.forEach((c, index, arr) => {
        if (ret === true && index !== 0) {
            const previousChar = String.fromCharCode(arr[index - 1].charCodeAt(0) + step)
            if (c !== previousChar) {
                ret = false
            }
        }
    })
    return ret;
}

export const isAllDigitsEqual = (num: number) => {
    const arr = num.toString().split('')
    return arr.filter(c => c === arr[0]).length === arr.length
}

