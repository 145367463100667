import { useState } from 'react';
import styles from './ActionBlock.module.scss'
import FxPaper from '../Paper/FxPaper'
import {CloseIcon} from '#icons'
import ChevronCircledLeft from '#pages/common/icons/ChevronCircledLeft'
import classNames from 'classnames'

interface ActionBlockProps {
    id?: string
    header: string
    className?: string
    children?: React.ReactNode
    size?: "large"
    onClose?: () => void
    onCollapse?: () => void
    defaultCollapsed?: boolean
    variant?: string
    icon?: string
    iconColor?: string
}

function ActionBlock({
    id,
    className,
    header,
    children,
    size,
    onClose,
    onCollapse,
    defaultCollapsed,
    variant = 'primary',
    icon,
    iconColor
}: ActionBlockProps) {
    const [collapsed, setCollapsed] = useState(!!onCollapse && defaultCollapsed)

    function onHeaderClick() {
        if (onCollapse) {
            setCollapsed(!collapsed)
            onCollapse()
        }
    }

    return (
        <FxPaper
            id={id}
            className={classNames(className, styles.body, {[styles.collapsableBody]: onCollapse}, {[styles.collapsed]: collapsed})}
            size={size}
        >
            <header className={classNames(styles.header, styles[variant], {[styles.collapsableHeader]: onCollapse})} onClick={onHeaderClick}>
                <h3>
                    {icon && <i style={{color: iconColor}} className={icon}/> }
                    <span>{header}</span></h3>
                {onClose && <CloseIcon onClick={onClose} className={styles.closeIcon}/>}
                {onCollapse && (
                    <ChevronCircledLeft className={classNames(styles.headerArrow, {[styles.headerArrowDown]: !collapsed})}/>
                )}
            </header>
            <div className={classNames(styles.content)}>
                {children}
            </div>
        </FxPaper>
    )
}

export default ActionBlock