import { useRef } from 'react';
import {Chart} from 'chart.js'
import 'chartjs-adapter-date-fns'
import {InterestOutput} from '#components'
import {FundInventoryReport, FundInventoryReturnPeriod} from '@fixrate/fixrate-report'
import {BURNT_ORANGE, CHART_COLORS, SILVER_GRAY} from '#app/colors/colors'
import {Box, Stack} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {fundInventoryReturnPeriodTMap} from '#services/enumTranslationKeyMapping'
import LegendSquare from "#pages/portfolio-depositor/Reports/FundReports/InventoryReport/LegendSquare"

const OPTIONAL_PERIODS: FundInventoryReturnPeriod[] = ['LAST_12_MONTHS', 'LAST_36_MONTHS', 'LAST_60_MONTHS']

type BarChart = Chart<'bar', string[] | number[]>
type Props = {
    report: FundInventoryReport
    pdfMode?: boolean
}

export default function ContributionAnalysisChart({report, pdfMode=false}: Props) {
    const {t} = useTranslation()
    const chartRef = useRef<BarChart | null>(null)

    const labels = Object.keys(report.returnRates)

    for (const [key, value] of Object.entries(report.returnRates)) {
        if (OPTIONAL_PERIODS.includes(key as FundInventoryReturnPeriod) && value == null) {
            labels.splice(labels.indexOf(key), 1)
        }
    }

    const canvasCallback = (canvas: HTMLCanvasElement | null) => {
        const ctx = canvas?.getContext('2d')
        if (ctx && !chartRef.current) {
            chartRef.current = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: labels,
                    datasets: [
                        {
                            label: t('pages-fund-reports-contribution-analysis-chart.labelPortfolio'),
                            data: Object.values(report.returnRates),
                            backgroundColor: BURNT_ORANGE[500],
                            borderColor: 'transparent',
                        },
                        ...report.fundPlacementInventoryEntries.map((entry, i) => (
                            {
                                label: entry.fundIsin + ' – ' + entry.fundName,
                                data: Object.values(entry.returnRates),
                                backgroundColor: CHART_COLORS[i],
                                borderColor: 'transparent',
                            }
                        ))
                    ],
                },
                options: {
                    color: SILVER_GRAY[500],
                    aspectRatio: 5 / 3,
                    maintainAspectRatio: true,
                    responsive: true,
                    indexAxis: 'y',
                    animation: pdfMode ? false : {duration: 500},
                    scales: {
                        x: {
                            display: true,
                            type: 'linear',
                            suggestedMax: 1,
                            ticks: {
                                font: {
                                    size: 10,
                                    family: "'Montserrat'",
                                    weight: '500',
                                },
                                stepSize: 0.1,
                                color: SILVER_GRAY[500],
                                callback: (value) => InterestOutput.format(value),
                            },
                            grid: {
                                drawBorder: false,
                                borderDash: [4, 4],
                                color: '#00000025',
                            },
                        },
                        y: {
                            type: 'category',
                            ticks: {
                                display: true,
                                font: {
                                    size: 10,
                                    family: "'Montserrat'",
                                    weight: '500',
                                },
                                color: SILVER_GRAY[500],
                                callback: (value, i) => t(fundInventoryReturnPeriodTMap[Object.keys(report.returnRates)[i]]),
                            },
                            grid: {
                                drawBorder: false,
                                display: false,
                            },
                        },
                    },
                    plugins: {
                        legend: {
                            display: false,
                        },
                        tooltip: {
                            enabled: true,
                            axis: 'x',
                            mode: 'index',
                            callbacks: {
                                title: (tooltipItem) => {
                                    return t(fundInventoryReturnPeriodTMap[Object.keys(report.returnRates)[tooltipItem[0].dataIndex]])
                                },
                                label: (tooltipItem) => {
                                    const dataset = chartRef.current.data.datasets[tooltipItem.datasetIndex]
                                    const value = dataset.data[tooltipItem.dataIndex]
                                    return value !== undefined && value !== null ? `${dataset.label}: ${InterestOutput.format(value)}` : ''
                                },
                            },
                        },

                    },
                },
            })
        }
    }

    const webStyle = {
        maxWidth: '100%'
    }
    const pdfStyle = {
        width: '85rem',
        height: '51rem',
    }
    const canvasStyle = pdfMode ? pdfStyle : webStyle


    return (
        <Stack direction={{xs: 'column', lg: 'row'}} spacing={2}>
            <Box sx={{maxWidth: '100%', width: '100rem'}}
            >
                <canvas ref={canvasCallback} style={canvasStyle} id={'contribution-analysis-chart-canvas'}/>
            </Box>
            <Stack spacing={1} direction={pdfMode ? 'row' : 'column'}>
                <Stack direction={'row'} alignItems={'start'} spacing={1}>
                    <LegendSquare color={BURNT_ORANGE[500]}/>
                    <Stack>
                        <Box component="span" sx={{fontSize: 12, fontWeight: 600}}>{t('pages-fund-reports-contribution-analysis-chart.labelPortfolio')}</Box>
                    </Stack>
                </Stack>
                {report.fundPlacementInventoryEntries.map((entry, i) => (
                    <Stack key={entry.fundId} direction={'row'} alignItems={'start'} spacing={1}>
                        <LegendSquare color={CHART_COLORS[i]}/>
                        <Stack>
                            <Box component="span" sx={{fontSize: 12, fontWeight: 600}}>{entry.fundName}</Box>
                            <Box component="span" sx={{fontSize: 12, color: '#6F7271'}}>{entry.fundIsin}</Box>
                        </Stack>
                    </Stack>
                ))}
            </Stack>
        </Stack>
    )
}
