import {Currency} from '@fixrate/fixrate-query'
import { createCurrencyFormatter } from './useCurrencyOutput'

type CurrencyOutputOptions = {
    withCurrency?: boolean,
    minimumDecimals?: number,
    maximumDecimals?: number,
    millionFormat?: boolean,
    currency?: Currency,
    locale?: string
}

const CurrencyOutputObject = (value: number, options?: CurrencyOutputOptions) => {
    const defaultOptions : CurrencyOutputOptions = {
        withCurrency: true,
        currency: 'NOK',
        minimumDecimals: 0,
        maximumDecimals: 0,
        millionFormat: false,
        locale: 'nb-NO'
    }

    const mergedOptions = { ...defaultOptions, ...options }
    const cappedValue = value < 0 ? Math.ceil(value / 1000000) : Math.floor(value / 1000000)
    const valutaFormatter = createCurrencyFormatter({currency: mergedOptions.currency, withCurrency: mergedOptions.withCurrency, minimumDecimals: mergedOptions.minimumDecimals, maximumDecimals: mergedOptions.maximumDecimals, locale: mergedOptions.locale})
    return valutaFormatter.format(mergedOptions.millionFormat ? cappedValue : value)
}

//The old way – use CurrencyOutputObject instead for more flexibility
const CurrencyOutput = {
    formatNoCode: (value: number, decimals = 2) => {
        const valutaFormatter = createCurrencyFormatter({withCurrency: false, minimumDecimals: decimals, maximumDecimals: decimals})
        if (value !== 0 && !value) return null
        return valutaFormatter.format(value)
    },
    formatMillion: (value: number, currency = undefined) => {
        const cappedValue = value < 0 ? Math.ceil(value / 1000000) : Math.floor(value / 1000000)
        const wholeMillion = cappedValue.toFixed(0)
        const formattedValue = wholeMillion.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
        return formattedValue + 'M ' + (currency ?? '')
    }
}

export { CurrencyOutput, CurrencyOutputObject }
