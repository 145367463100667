import {createSlice} from '@reduxjs/toolkit'

const slice = createSlice({
    name: 'loaded',
    initialState: {},
    reducers: {
        setIsLoaded(state, action) {
            state[action.payload] = true
        },
        setIsNotLoaded(state, action) {
            state[action.payload] = false
        },
    },
})

const {actions, reducer} = slice
export const {setIsLoaded, setIsNotLoaded} = actions
export default reducer