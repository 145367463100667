import config from '../../config'
import {syncSessionState} from '#services/thunks/session'
import {addInfoMessage} from '#services/thunks/infoMessages'
import {translate} from '#services/i18n'

const API_BASE_URL = config().apiUrl

type HandleStatusCodeErrorsType = (dispatch: any, response: Response) => Promise<Response>
type HandleErrorsType = (dispatch: any, error: any) => Promise<any>
type HandleHeadersType = (headers: Headers) => void
type ParserType = (response: Response) => Promise<any>

type CustomHandlersType = {
    // Will replace the default error handling of status codes
    // Only use this to reject a response or display a message to the user.
    // Provide a custom "parse" function if you want to change the response from the call based on the status code.
    handleStatusCodeErrors?: HandleStatusCodeErrorsType,

    // Will replace the default error handling of rejected promises (usually network errors)
    handleErrors?: HandleErrorsType,

    // Will run before status code error handling
    handleHeaders?: HandleHeadersType,

    // Will run as last handler if no error has been thrown.
    // Use this to parse the response body or provide a custom response based on the status code.
    parse?: ParserType,

    // The default handleStatusCodeErrors will not result in a rejected Promise for any status code
    // If acceptedStatusCodes is present, and if the status code is not in the list, the call will result in a rejected Promise
    // If a custom handleStatusCodeErrors is present and it returns a rejected Promise, then the call will also result in a rejected Promise
    // TODO: A better default solution would be to reject the promise if the status code is not in the 200-range, but this requires a walktrough of current usage
    acceptedStatusCodes?: number[],
}

const STANDARD_JSON_HEADERS: HeadersInit = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
}

// Convenience handlers for typical use cases
export const REST_HANDLERS = {

    // This handler will ignore status codes completely.
    IGNORE_STATUS_CODES: (dispatch, response) => Promise.resolve(response),

    // This handler will reject the promise if the status code is not in the 200-range. No error message to the user is displayed.
    FAIL_ON_STATUS_CODE_ERRORS: (dispatch, response) => {
        if (!response.ok) return Promise.reject('Status code ' + response.status)
        return Promise.resolve(response)
    },

    // This handler will forward the rejected promise to the caller without displaying an error message to the user
    NO_ERROR_MESSAGES: (dispatch, error) => Promise.reject(error),
}

/**
 * Default error handling for status codes
 * <p>
 * Will dispatch an error message to the user on http status codes that are not OK
 * Will return the response back after the error message is displayed
 */
export async function defaultHandleStatusCodeErrors(dispatch, response: Response): Promise<Response> {

    if (!response.ok) {

        const clone = response.clone()
        switch (response.status) {

            case 401:
                dispatch(syncSessionState())
                break

            case 403:
            case 413:
            case 500:
            case 503:
                dispatch(addInfoMessage('error', translate('error.status.' + response.status + '.header'), translate('error.status.' + response.status + '.message')))
                break
            default:
                if (clone.headers.get('content-type') === 'application/json') {
                    const json = await clone.json()
                    console.error('Network response', response.statusText, json)

                    if (json?.messageKey) {
                        dispatch(addInfoMessage('error', translate('error.header'), translate('command.error.' + json.messageKey)))
                    } else if (json?.message) {
                        dispatch(addInfoMessage('error', translate('error.header'), json.message))
                    } else {
                        dispatch(addInfoMessage('error', translate('error.header'), translate('error.default')))
                    }

                } else {
                    const text = await clone.text()
                    console.error('Network response', response.statusText, text)
                    const message = (text && text.length < 300) ? text : translate('error.default')
                    dispatch(addInfoMessage('error', translate('error.header'), message))
                }
                break
        }
    }
    return response
}

/**
 * Default parser
 */
async function defaultParser(response: Response): Promise<any> {
    try {
        return await response.json()
    } catch (err) {
        console.error('Failed to parse response as json', err)
        return Promise.reject()
    }
}

/**
 * Default handler for rejected promises (usually network errors)
 */
export function defaultHandleErrors(dispatch, error): Promise<never> {
    if (error) {
        if (error.name === 'TypeError' &&
            (error.message === 'Failed to fetch' || error.message === 'NetworkError when attempting to fetch resource.')
        ) {
            // Log this as a warning. This is likely a temporary network error.
            console.warn('Failed to fetch', error)
        } else {
            console.error('Error while calling network service', error)
        }
            dispatch(addInfoMessage('error', translate('error.header'), translate('error.network')))
    }
    return Promise.reject(error)
}

/**
 * The function that actually performs the request
 */
function doFetch(dispatch, path: string, options: RequestInit, customHandlers: CustomHandlersType) {

    const handlers: CustomHandlersType = {
        handleErrors: customHandlers.handleErrors || defaultHandleErrors,
        handleStatusCodeErrors: customHandlers.handleStatusCodeErrors || defaultHandleStatusCodeErrors,
        handleHeaders: customHandlers.handleHeaders || (headers => 0),
        parse: customHandlers.parse || defaultParser,
    }

    return fetch(API_BASE_URL + path, options)
        .then((response) => {
            handlers.handleHeaders(response.headers)
            return response
        })
        .then((response) => handlers.handleStatusCodeErrors(dispatch, response))
        .then((response) => {
            if (customHandlers.acceptedStatusCodes && customHandlers.acceptedStatusCodes.indexOf(response.status) === -1) {
                return Promise.reject('Status code ' + response.status + ' not in the list of accepted status codes (' + customHandlers.acceptedStatusCodes + ')')
            }
            return response
        })
        .then(handlers.parse)
        .catch((ex) => handlers.handleErrors(dispatch, ex))
}

/**
 * Basic GET function
 */
export function GET(dispatch, path: string, customHandlers: CustomHandlersType = {}, signal?: AbortSignal): Promise<any> {
    const options: RequestInit = {
        headers: {
            'Accept': 'application/json',
        },
        credentials: 'include',
        signal: signal
    }
    return doFetch(dispatch, path, options, customHandlers)
}

/**
 * Basic POST function
 */
export function POST_RAW(dispatch, path, body, customHeaders?: HeadersInit, customHandlers: CustomHandlersType = {}): Promise<any> {
    const headers = customHeaders || STANDARD_JSON_HEADERS
    const options: RequestInit = {
        headers,
        credentials: 'include',
        method: 'POST',
        body: body,
    }
    return doFetch(dispatch, path, options, customHandlers)
}

/**
 * Basic POST function that ignores the response
 */
export function POST_NO_RESPONSE_RAW(dispatch, path: string, body, customHeaders?: HeadersInit, customHandlers: CustomHandlersType = {}): Promise<any> {
    const headers = customHeaders || {
        'Content-Type': 'application/json',
    }
    const handlers = {
        parse: () => Promise.resolve(),  // parse function that ignores the response
        ...customHandlers
    }
    return POST_RAW(dispatch, path, body, headers, handlers)
}

/**
 * Convenience POST function that serializes the body as JSON
 */
export function POST(dispatch, path, body, customHandlers: CustomHandlersType = {}): Promise<any> {
    return POST_RAW(dispatch, path, body && JSON.stringify(body), STANDARD_JSON_HEADERS, customHandlers)
}

/**
 * Convenience POST function that serializes the body as JSON and ignores the response
 */
export function POST_NO_RESPONSE(dispatch, path: string, body, customHandlers: CustomHandlersType = {}): Promise<any> {
    return POST_NO_RESPONSE_RAW(dispatch, path, body && JSON.stringify(body), STANDARD_JSON_HEADERS, customHandlers)
}

export function GET_WITHOUT_ERROR_MSG(dispatch, path: string, signal?: AbortSignal): Promise<any> {
    return GET(dispatch, path, {handleStatusCodeErrors: REST_HANDLERS.FAIL_ON_STATUS_CODE_ERRORS, handleErrors: REST_HANDLERS.NO_ERROR_MESSAGES}, signal)
}

export function DELETE(dispatch, path): Promise<Response> {
    const options: RequestInit = {
        headers: STANDARD_JSON_HEADERS,
        credentials: 'include',
        method: 'DELETE',
    }
    return fetch(API_BASE_URL + path, options)
        .then((response) => defaultHandleStatusCodeErrors(dispatch, response))
        .catch((ex) => defaultHandleErrors(dispatch, ex))
}
