const HOTJAR_ID = {
    'market.fixrate.no': 1473001,
    'market.fixrate.se': 1473001,
    'market.test.fixrate.no': 1483263,
}

const GA_CODE = {
    'market.fixrate.no': 'UA-104417895-1',
    'market.fixrate.se': 'UA-104417895-1',
}

const GA4_CODE = {
    'market.fixrate.no': 'G-SWT84XV1B7',
    'market.fixrate.se': 'G-SWT84XV1B7',
    'market.test.fixrate.org': 'G-W3RWZ25RFR',
}

const HUBSPOT_ID = {
    'market.fixrate.no': '7525826',
    'market.fixrate.se': '7525826',
    //['market.test.fixrate.org']: '7525826',
}

const FBPIX_ID = {
    'market.fixrate.se': '983717338650215',
}

const LINKEDIN_PIXEL_URL = {
    'market.fixrate.se': 'https://px.ads.linkedin.com/collect/?pid=1732193&conversionId=1803004&fmt=gif',
}

const SENTRY_URL = {
    'market.fixrate.no': 'https://d32e50e9879e46b28f5684f0b968bbcf@sentry.io/290368',
    'market.fixrate.se': 'https://d32e50e9879e46b28f5684f0b968bbcf@sentry.io/290368',
    'demo.fixrate.no': 'https://d32e50e9879e46b28f5684f0b968bbcf@sentry.io/290368',
}

const COOKIEYES_CODE = {
    'market.stage.fixrate.org': '623a15a00680bd72fe302c35',
    'market.fixrate.no': '623a15a00680bd72fe302c35',
}

const PING_FREQUENCY = {
    'market.fixrate.no': 30000,
    'market.fixrate.se': 30000,
    'demo.fixrate.no': 30000,
    'market.test.fixrate.no': 30000,
    'market.cypress.fixrate.no': 30000,
    'market.test.fixrate.org': 30000,
}

const ADMIN_URL = {
    'market.fixrate.no': 'https://admin.fixrate.no',
    'market.test.fixrate.org': 'https://admin.test.fixrate.org',
    'market.cypress.fixrate.org': 'https://admin.cypress.fixrate.org',
    'market.test.fixrate.no': 'https://admin.test.fixrate.no',
    'market.stage.fixrate.org': 'https://admin.stage.fixrate.org',
    'demo.fixrate.no': 'https://admin.demo.fixrate.no',
    'market.fixrate.dev': 'https://admin.fixrate.dev',
}

const KPI_DASHBOARD_URL = {
    'market.fixrate.no': 'https://dashboard.fixrate.no',
    'market.test.fixrate.org': 'https://dashboard.test.fixrate.org',
    'market.cypress.fixrate.org': 'https://dashboard.cypress.fixrate.org',
    'market.test.fixrate.no': 'https://dashboard.test.fixrate.no',
    'market.stage.fixrate.org': 'https://dashboard.stage.fixrate.org',
    'demo.fixrate.no': 'https://dashboard.demo.fixrate.no',
    'market.fixrate.dev': 'https://dashboard.fixrate.dev',
}

const MARKET_URL = {
    'bank.fixrate.no': 'https://market.fixrate.no',
    'bank.test.fixrate.org': 'https://market.test.fixrate.org',
    'bank.cypress.fixrate.org': 'https://market.cypress.fixrate.org',
    'bank.test.fixrate.no': 'https://market.test.fixrate.no',
    'bank.stage.fixrate.org': 'https://market.stage.fixrate.org',
    'bank.demo.fixrate.no': 'https://market.demo.fixrate.no',
    'bank.fixrate.dev': 'https://market.fixrate.dev',
    'localhost:3010': 'http://localhost:3000',
}

const FIXRATE_CAPITAL_BASE_URL = {
    'market.fixrate.no': 'https://fixratecapital.no',
    'market.test.fixrate.org': 'https://test.fixratecapital.no',
    'market.test.fixrate.no': 'https://test.fixratecapital.no',
    'market.stage.fixrate.org': 'https://stage.fixratecapital.no',
    'demo.fixrate.no': 'https://demo.fixratecapital.no',
    'market.fixrate.dev': 'https://capital.fixrate.dev',
    'localhost:3000': 'http://localhost:3005',
}

type Env = 'DEV' | 'STAGE' | 'PROD' | 'TEST' | 'DEMO'

const ENVIRONMENT: {[url: string]: Env} = {
    'market.fixrate.no': 'PROD',
    'market.fixrate.se': 'PROD',
    'market.test.fixrate.org': 'TEST',
    'market.fixrate.se.test.fixrate.org': 'TEST',
    'market.cypress.fixrate.org': 'TEST',
    'market.test.fixrate.no': 'TEST',
    'market.fixrate.se.stage.fixrate.org': 'STAGE',
    'demo.fixrate.no': 'DEMO',
    'demo.fixrate.se': 'DEMO',
    'market.fixrate.dev': 'DEV',
    'market.fixrate.se.fixrate.dev': 'DEV',
}

type App = 'MARKETPLACE' | 'BANK'

const APP: {[url: string]: App} = {
    'market.fixrate.no': 'MARKETPLACE',
    'market.fixrate.se': 'MARKETPLACE',
    'market.test.fixrate.org': 'MARKETPLACE',
    'market.fixrate.se.test.fixrate.org': 'MARKETPLACE',
    'market.cypress.fixrate.org': 'MARKETPLACE',
    'market.test.fixrate.no': 'MARKETPLACE',
    'market.stage.fixrate.no': 'MARKETPLACE',
    'market.fixrate.se.stage.fixrate.no': 'MARKETPLACE',
    'demo.fixrate.no': 'MARKETPLACE',
    'market.fixrate.dev': 'MARKETPLACE',
    'market.fixrate.se.fixrate.dev': 'MARKETPLACE',
    'localhost:3000': 'MARKETPLACE',
}

const HOMEPAGE_URL = {
    'market.fixrate.no': 'https://fixrate.no',
    'market.fixrate.se': 'https://fixrate.se',
    'market.test.fixrate.org': 'https://test.fixrate.org',
    'market.cypress.fixrate.org': 'https://cypress.fixrate.org',
    'market.test.fixrate.no': 'https://test.fixrate.no',
    'market.fixrate.se.test.fixrate.no': 'https://fixrate.se.test.fixrate.no',
    'market.stage.fixrate.org': 'https://stage.fixrate.org',
    'market.fixrate.se.stage.fixrate.org': 'https://fixrate.se.stage.fixrate.org',
    'demo.fixrate.no': 'https://demo.fixrate.no',
    'market.fixrate.dev': 'https://fixrate.dev',
    'market.fixrate.se.fixrate.dev': 'https://fixrate.se.fixrate.dev',
    'localhost:3000': 'http://localhost:2999',
    'localhost:3010': 'http://localhost:2999',
}


const config = {
    // @ts-ignore because MARKETPLACE_VERSION will be replaced by Webpack
    marketplaceVersion: MARKETPLACE_VERSION,
    apiUrl: '',
    adminUrl: ADMIN_URL[window.location.host],
    kpiDashboardUrl: KPI_DASHBOARD_URL[window.location.host],
    marketUrl: MARKET_URL[window.location.host],
    websocketBaseUrl: (window.location.protocol === 'https:' ? 'wss://' : 'ws://') + window.location.host,
    ga: GA_CODE[window.location.host],
    ga4: GA4_CODE[window.location.host],
    hubspot: HUBSPOT_ID[window.location.host],
    fbPix: FBPIX_ID[window.location.host],
    hotjar: HOTJAR_ID[window.location.host],
    sentryUrl: SENTRY_URL[window.location.host],
    linkedInPixelUrl: LINKEDIN_PIXEL_URL[window.location.host],
    bankIdStatusUrl: 'https://market.fixrate.no/bankid-status',
    pingFrequencey: PING_FREQUENCY[window.location.host] || 30000,
    enableReduxTools: ENVIRONMENT[window.location.host] !== 'PROD',
    enableAdvancedSessions: false,
    enableFixtureReload: ENVIRONMENT[window.location.host] === 'DEV' || ENVIRONMENT[window.location.host] === 'TEST' || ENVIRONMENT[window.location.host] === 'DEMO',
    enableBankInfo: ENVIRONMENT[window.location.host] !== 'PROD',
    enableBenchmark: ENVIRONMENT[window.location.host] !== 'PROD',
    enableIntegrations: true,
    sentryEnv: window.location.host,
    isProduction: ENVIRONMENT[window.location.host] === 'PROD',
    isStage: ENVIRONMENT[window.location.host] === 'STAGE',
    enableDepositDeviations: true,
    environment: ENVIRONMENT[window.location.host] ?? 'DEV',
    app: APP[window.location.host],
    fixrateCapitalBaseUrl: FIXRATE_CAPITAL_BASE_URL[window.location.host],
    homePageUrl: HOMEPAGE_URL[window.location.host],
    fixrateFrontDocumentUrl: 'https://fixrate-public.s3.eu-central-1.amazonaws.com/fixrate-front',
    deviationGuideDocument: 'Kilder%20til%20avvik%20%E2%80%93%20En%20veileder%20for%20bank.pdf',
    cookieYesCode: COOKIEYES_CODE[window.location.host],
}

console.log(`Build configuration for ${window.location.host}`, config)

const read = () => config

export default read
