import {createSlice} from '@reduxjs/toolkit'

const slice = createSlice({
    name: 'adStatus',
    initialState: {},
    reducers: {
        setAdStatus(state, action) {
            return action.payload
        },
    },
})

const {actions, reducer} = slice
export const {setAdStatus} = actions
export default reducer