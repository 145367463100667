import { useEffect, useRef, useState } from 'react';
import * as React from 'react';
import { LoadingButtonProps, LoadingButton as MUIButton } from '@mui/lab'

type Props = LoadingButtonProps & {
    propsOnClick?: () => void
    onError?: (err: Error) => void
    children?: React.ReactNode
}

const AsyncButton = function( {
    onClick: propsOnClick = () => undefined,
    onError: propsOnError = (err) => {console.error('Failed in Button onClick handler', err)},
    children = null,
    loading = false,
    disabled = false,
    variant = 'contained',
    size = 'medium',
    id = null,
    color,
    ...props
} : Props ) {

    const [isWorking, setWorking] = useState(false)

    const isMounted = useRef(false)
    useEffect(() => {
        isMounted.current = true
        return () => {
            isMounted.current = false
        }
    }, [])

    async function handleClick(e) {
        try {
            setWorking(true)
            await propsOnClick(e)
        } catch (err) {
            propsOnError(err)
        } finally {
            if (isMounted.current && setWorking) setWorking(false)
        }
    }

    async function handleKeys(e) {
        const key = e?.keyCode || e?.which
        if (key === 32 && e?.key?.toLowerCase?.() === 'spacebar') {
            e?.target?.click?.()
        }
    }

    return (
        <MUIButton
            {...props}
            sx={{...props.sx }}
            onClick={(e) => handleClick(e)}
            id={id}
            onKeyDown={handleKeys}
            loading={isWorking || loading || null}
            disabled={isWorking || disabled || null}
            data-cy-disabled={(isWorking || disabled) ? 'true' : 'false'}
            size={size}
            color={color || 'primary'}
            variant={variant}>
            {children}
        </MUIButton>
    )
}

export default AsyncButton
