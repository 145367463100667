import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import Backend from 'i18next-http-backend'
import PhraseInContextEditorPostProcessor from 'i18next-phrase-in-context-editor-post-processor'
import config from "#app/config";
import {parse} from 'query-string'
import LanguageDetector from 'i18next-browser-languagedetector';
import {onLanguageChanged} from "@fixrate/fixrate-analytics-widgets";
import {sendSentryMessage} from '#services/sentry'

const isProduction = config().isProduction;
const query = parse(window.location.search)
const enablePhrase = !isProduction && query.translate

export type AvailableLanguage = "en" | "sv" | "nb"
export const supportedLanguages: Record<AvailableLanguage, string> = {
    en: 'English',
    nb: 'Norsk',
    sv: 'Svenska',
}

export const languageFlags: Record<AvailableLanguage, string> = {
    en: 'fi fi-gb',
    nb: 'fi fi-no',
    sv: 'fi fi-se',
}

i18n
    .use(Backend)
    .use(new LanguageDetector(null, {
        order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator'],
        lookupQuerystring: 'lng',
        lookupCookie: 'i18next',
        lookupLocalStorage: 'i18nextLng',
        lookupSessionStorage: 'i18nextLng',
        // cache user language
        caches: ['localStorage'],
        excludeCacheFor: ['cimode'],
    }))
    .use(new PhraseInContextEditorPostProcessor({
        phraseEnabled: enablePhrase,
        autoLowercase: false,
        projectId: '457cb455b8e41e6c24e49a3bc7ac9dea'
    }))
    .use(initReactI18next)
    .init({
        fallbackLng: 'nb',
        debug: true,

        // have a common namespace used around the full app
        //    ns: ["common", "menu", "profile"],
        //    defaultNS: "common",
        ns: ["translations"],
        defaultNS: "translations",

        // This will automatically map nb_US, en_GB to en, nb_NO to nb, and similarly for other languages
        supportedLngs: Object.keys(supportedLanguages),
        nonExplicitSupportedLngs: true,

        keySeparator: '.',

        // Log missing keys as errors to be picked up by Sentry
        saveMissing: true,
        missingKeyHandler: (lng, ns, key) => {
            console.warn(`Missing translation key: ${key} (${lng}/${ns})`)
            sendSentryMessage('Missing translation key', {key, lng, ns}, 'error')
        },

        interpolation: {
            escapeValue: false,
            skipOnVariables: enablePhrase
        },
        backend: {
            // @ts-ignore because MARKETPLACE_VERSION will be replaced by Webpack
            loadPath: '/locales/{{lng}}/{{ns}}.json?v=' + MARKETPLACE_VERSION,
        },
        postProcess: ['phraseInContextEditor'],
        react: {
            useSuspense: true,
        },
    });

i18n.on('languageChanged', lng => {
    onLanguageChanged(lng)
})

export const translate = i18n.getFixedT(null, '', '')

export default i18n;
