import { createContext, useContext } from 'react';
import {
    DELETE,
    GET,
    GET_WITHOUT_ERROR_MSG,
    POST,
    POST_NO_RESPONSE,
    POST_NO_RESPONSE_RAW
} from '../network/rest-connection'

import {useDispatch} from 'react-redux'
import { UserInviteDto } from '@fixrate/fixrate-security';

const getEndPoints = (dispatch) => Object.freeze({

    getSession: () => GET(dispatch, '/api/session'),

    getSessionReadOnly: () => GET(dispatch, '/api/session/readonly'),

    unlockSession: (pin) => POST_NO_RESPONSE_RAW(dispatch, '/api/session/unlock', pin),

    logout: () => POST_NO_RESPONSE(dispatch, '/api/session/logout', false),

    hardLogout: () => POST_NO_RESPONSE(dispatch, '/api/session/logout', true),

    registerLanding: (url, referrer) => POST_NO_RESPONSE(dispatch, '/api/landing/', {url: url, referrer: referrer}),

    registerUser: (user) => POST(dispatch, '/api/registration', user),

    getBankName: (bankIdentifier) => GET(dispatch, `/api/bankmapping?bankIdentifier=${bankIdentifier}`),

    dismissHintsModal: (hideInFuture) => POST_NO_RESPONSE_RAW(dispatch, '/api/session/dismissHintsModal', JSON.stringify(hideInFuture)),

    potentialInterest: (potentialVolume, historicInterestRate) => POST(dispatch, '/api/pubstat/potential-interest', {potentialVolume, historicInterestRate}),

    getPotentialInterest: (potentialVolume) => GET(dispatch, '/api/pubstat/potential-interest?potentialVolume=' + potentialVolume),

    getTimeToFullySubscribeAd: (volume, interestRate, bankId) => POST(dispatch, 'api/pubstat/calculated-ad-time', {volume, interestRate, bankId}),

    getSupportArticleByUrl: (url) => GET(dispatch, '/api/support/article?url=' + encodeURIComponent(url)),

    getSupportArticleById: (id) => GET(dispatch, '/api/support/article/' + id),

    changeSupportArticleIndex: (id, change) => POST_NO_RESPONSE(dispatch, '/api/support/article/' + id + '/index', change),

    saveSupportArticle: (article) => POST(dispatch, '/api/support/article', article),

    deleteSupportArticle: (id) => DELETE(dispatch, '/api/support/article/' + id),

    getBankPresentation: (bankId) => GET(dispatch, '/api/bank/' + bankId),

    getDepositDailyLiquidity: (depositorId) => GET(dispatch, `/api/deposit/daily-liquidity?depositorId=${depositorId}`),
    getFundDailyLiquidity: (depositorId) => GET(dispatch, `/api/fund/daily-liquidity?depositorId=${depositorId}`),

    getLastOrders: () => GET(dispatch, '/api/stagg/orders/last', {acceptedStatusCodes: [502]}),

    getDepth: (range) => GET(dispatch, '/api/stagg/depth/all' + (range ? '?range=' + range : '')),
    getDepthByProduct: (range) => GET(dispatch, '/api/stagg/depth/product' + (range ? '?range=' + range : '')),
    getDepthByBankSegment: (range) => GET(dispatch, '/api/stagg/depth/banksegment' + (range ? '?range=' + range : '')),

    getDepthByInterestForNiborProducts: (range) => GET(dispatch, '/api/stagg/depth/interest-nibor-product' + (range ? '?range=' + range : '')),
    getDepthByInterestForFixedProducts: (range) => GET(dispatch, '/api/stagg/depth/interest-fixed-product' + (range ? '?range=' + range : '')),
    getDepthForBank: (bankId, range) => GET(dispatch, '/api/stagg/depth/bank/' + bankId + (range ? '?range=' + range : '')),
    getMeanDepthByBankSegment: (range) => GET(dispatch, '/api/stagg/depth/banksegment-mean' + (range ? '?range=' + range : '')),

    getTurnover: (range) => GET(dispatch, '/api/stagg/turnover/all' + (range ? '?range=' + range : '')),
    getTurnoverByProduct: (range) => GET(dispatch, '/api/stagg/turnover/product' + (range ? '?range=' + range : '')),
    getTurnoverForBank: (bankId, range) => GET(dispatch, '/api/stagg/turnover/bank/' + bankId + (range ? '?range=' + range : '')),
    getTurnoverByProductForBank: (bankId, range) => GET(dispatch, '/api/stagg/turnover/bank-product/' + bankId + (range ? '?range=' + range : '')),

    getBusinessVolume: () => GET(dispatch, '/api/stagg/deposits/business?range=NOW'),
    getBusinessVolumeForBank: (bankId) => GET(dispatch, '/api/stagg/deposits/bank-business/' + bankId + '?range=NOW'),
    getDepositVolumeByBankSegment: (range) => GET(dispatch, '/api/stagg/deposits/banksegment' + (range ? '?range=' + range : '')),
    getMeanDepositVolumeByBankSegment: (range) => GET(dispatch, '/api/stagg/deposits/banksegment-mean' + (range ? '?range=' + range : '')),

    getDepositInterestByBankSegment: (range) => GET(dispatch, '/api/stagg/interest/banksegment' + (range ? '?range=' + range : '')),
    getDepositInterestByBankSegmentWithSsb: (range) => GET(dispatch, '/api/stagg/interest/banksegment-with-ssb' + (range ? '?range=' + range : '')),
    getDepositInterestByProduct: (range) => GET(dispatch, '/api/stagg/interest/product' + (range ? '?range=' + range : '')),
    getDepositInterestForBank: (bankId, range) => GET(dispatch, '/api/stagg/interest/bank/' + bankId + (range ? '?range=' + range : '')),
    getDepositInterestByProductForBank: (bankId, range) => GET(dispatch, '/api/stagg/interest/bank-product/' + bankId + (range ? '?range=' + range : '')),
    getDepositInterestForDepositor: (depositorId, range) => GET(dispatch, '/api/stagg/interest/depositor/' + depositorId + (range ? '?range=' + range : '')),
    getDepositInterestByProductForDepositor: (depositorId, range) => GET(dispatch, '/api/stagg/interest/depositor-product/' + depositorId + (range ? '?range=' + range : '')),
    getDepositInterestForDepositorWithSsb: (depositorId, range) => GET(dispatch, '/api/stagg/interest/depositor-ssb/' + depositorId + (range ? '?range=' + range : '')),
    getDepositInterestForPortfolioWithSsb: (portfolioId: string, range: string) => GET(dispatch, '/api/stagg/interest/portfolio-ssb/' + portfolioId + (range ? '?range=' + range : '')),


    getDepositMarginByBankSegment: (range) => GET(dispatch, '/api/stagg/interest/margin-banksegment' + (range ? '?range=' + range : '')),
    getDepositMarginByProduct: (range) => GET(dispatch, '/api/stagg/interest/margin-product' + (range ? '?range=' + range : '')),
    getDepositMarginForBank: (bankId, range) => GET(dispatch, '/api/stagg/interest/margin-bank/' + bankId + (range ? '?range=' + range : '')),
    getDepositMarginByProductForBank: (bankId, range) => GET(dispatch, '/api/stagg/interest/margin-bank-product/' + bankId + (range ? '?range=' + range : '')),
    getDepositMarginForDepositor: (depositorId, range) => GET(dispatch, '/api/stagg/interest/margin-depositor/' + depositorId + (range ? '?range=' + range : '')),
    getDepositMarginByProductForDepositor: (depositorId, range) => GET(dispatch, '/api/stagg/interest/margin-depositor-product/' + depositorId + (range ? '?range=' + range : '')),

    getAdInterestByProduct: (range) => GET(dispatch, '/api/stagg/ads/interest-product' + (range ? '?range=' + range : '')),
    getAdInterestByBankSegment: (range) => GET(dispatch, '/api/stagg/ads/interest-banksegment' + (range ? '?range=' + range : '')),
    getAdInterestForBank: (bankId, range) => GET(dispatch, '/api/stagg/ads/interest-bank/' + bankId + (range ? '?range=' + range : '')),

    getAdMarginByProduct: (range) => GET(dispatch, '/api/stagg/ads/margin-product' + (range ? '?range=' + range : '')),
    getAdMarginByBankSegment: (range) => GET(dispatch, '/api/stagg/ads/margin-banksegment' + (range ? '?range=' + range : '')),
    getAdMarginForBank: (bankId, range) => GET(dispatch, '/api/stagg/ads/margin-bank/' + bankId + (range ? '?range=' + range : '')),

    getNibor: (range) => GET(dispatch, '/api/stagg/nibor/with-folio' + (range ? '?range=' + range : '')),

    getInvite: (inviteId: string, signal?: AbortSignal) => GET_WITHOUT_ERROR_MSG(dispatch, '/api/registration/invite/' + inviteId, signal),

    getInvoicesByBank: (bankId) => GET(dispatch, '/document/invoice/' + bankId),

    getFixtures: () => GET(dispatch, '/api/fixtures'),

    getSsoAuthorizationEndpoint: (idpId) => GET(dispatch, '/api/sso/start?idpId=' + idpId),

    getNextBankDay: (date) => GET(dispatch, '/api/bankcalendar/no/next?date=' + date),

    getBankCalendar: () => GET(dispatch, '/api/bankcalendar/no/closed'),

    getCustomerDeclarationChangelog: (depositorId) => GET(dispatch, '/api/kyc/bank/changelog/' + depositorId),

    getAccountingReport: (depositorId, startDate, endDate) => GET(dispatch, `/api/report/fund-accounting?depositorId=${depositorId}&startDate=${startDate}&endDate=${endDate}`, {acceptedStatusCodes: [200]}),
    getInventoryReport: (depositorId, startDate, endDate) => GET(dispatch, `/api/report/fund-inventory?depositorId=${depositorId}&startDate=${startDate}&endDate=${endDate}`, {acceptedStatusCodes: [200]}),

    getTokenForFixrateCapital: () => {
        return fetch('/api/session/token', {credentials: 'include', headers: {'Content-Type': 'application/json'}})
            .then(response => response.json())
    },

    setCurrencyBankSession: (currency: string) => POST_NO_RESPONSE(dispatch, '/api/session/currency', currency),

    setPortfolioDepositorSession: (portfolioId: string) => POST_NO_RESPONSE(dispatch, '/api/session/portfolioId', portfolioId),

})

const EndPointContext = createContext(null)

const EndPointProvider = function ({children}: { children?: React.ReactNode}) {
    const dispatch = useDispatch()
    const endPoints = getEndPoints(dispatch)
    return (
        <EndPointContext.Provider value={endPoints}>
            {children}
        </EndPointContext.Provider>
    )
}

const useEndpoint = function () {
    const context = useContext(EndPointContext)
    if (!context) {
        throw new Error('useEndPoint can only be used within a EndPointProvider')
    }

    return context
}


export {
    EndPointProvider,
    useEndpoint
}
