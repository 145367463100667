import { Dialog, DialogProps, useMediaQuery, useTheme } from '@mui/material'

type Props = DialogProps & {
    fullScreen?: never
}

export default function FxDialog(props: Props) {
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

    return (
        <Dialog {...props} fullScreen={fullScreen}>
            {props.children}
        </Dialog>
    )
}
