import merge from 'lodash/merge'
import {defaults} from 'react-chartjs-2'
import {Chart, registerables} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import {FOREST_GREEN, SILVER_GRAY} from '#app/colors/colors'

// chart.js configuration
export function initializeChartJs() {

    // react-chartjs-2
    merge(defaults, {
        global: {
            defaultFontFamily: 'Montserrat',
            defaultFontColor: 'rgba(0, 0, 0, 0.7)',
            defaultFontSize: 14,
            defaultUsePointStyle: true,
            elements: {
                line: {
                    fill: false,
                },
            },
            legend: {
                labels: {
                    usePointStyle: true,
                },
            },
        },
    })

    // chart.js
    Chart.register(...registerables, ChartDataLabels)
    Chart.defaults.plugins.tooltip = {
        ...Chart.defaults.plugins.tooltip,
        backgroundColor: '#ffffff',
        borderColor: SILVER_GRAY[500],
        cornerRadius: 0,
        borderWidth: 2,
        titleColor: FOREST_GREEN[500],
        bodyColor: FOREST_GREEN[500],
        footerColor: FOREST_GREEN[500],
    }
    Chart.defaults.plugins.datalabels = {
        ...Chart.defaults.plugins.datalabels,
        display: false,
        font: {
            size: 14,
            family: '\'Montserrat\'',
            weight: 'bold',
        },
    }
}
