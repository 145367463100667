import { useState } from 'react';
import {DateOutput} from "#components"
import {parse, isValid} from "date-fns"

const DummyDatePicker = ({id, onChange, 'data-cy': dataCy}) => {

    const [testValue, setTestValue] = useState('')
    const dummyValue = DateOutput.formatDate(new Date());
    console.log('DummyDatePicker: ' + dataCy)
    const onTestInput = (e) => {
        if(e.target?.value?.length === dummyValue.length && isValid(parse(e.target.value, DateOutput.dateFormat, new Date()))) {
            onChange(parse(e.target.value, DateOutput.dateFormat, new Date()))
            setTestValue(dummyValue)
        } else {
            setTestValue(e.target.value)
        }
    }

    return (
        <input id={id} type="text" onChange={onTestInput} value={testValue} data-cy={dataCy}/>
    )
}

export default DummyDatePicker
