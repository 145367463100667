import { COLORS, PURPLE } from '#app/colors/colors';
import { useCurrencyOutputWithCurrency } from '#app/components/CurrencyOutput/useCurrencyOutput';
import ContributionAnalysisChart from '#app/pages/portfolio-depositor/Reports/FundReports/InventoryReport/ContributionAnalysisChart';
import FundsDevelopmentChart from '#app/pages/portfolio-depositor/Reports/FundReports/InventoryReport/FundsDevelopmentChart';
import InventoryTable from '#app/pages/portfolio-depositor/Reports/FundReports/InventoryReport/InventoryTable';
import PortfolioSplitChart from '#app/pages/portfolio-depositor/Reports/FundReports/InventoryReport/PortfolioSplitChart';
import ReturnsTable from '#app/pages/portfolio-depositor/Reports/FundReports/InventoryReport/ReturnsTable';
import { DateOutput, LoadingSpinner, PageHeader, PageLayout } from '#components';
import { InterestBadge } from '#components/InterestBadge/InterestBadge';
import FixrateLogo from "#pages/menu/FixrateLogo";
import styles from "#pages/portfolio-depositor/Reports/FundReports/AccountingReport/AccountingReport.module.scss";
import { formatLongReportPeriod, getReportPeriodDates } from '#pages/portfolio-depositor/Reports/ReportPeriods';
import usePortfolio from "#services/usePortfolio";
import { FundInventoryReport, FundInventoryReportPdfProps } from '@fixrate/fixrate-report';
import { useSelector } from '#state/useSelector';
import { Currency } from "@fixrate/fixrate-query";
import { Alert, Box, Button, Stack, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from "react-query";
import { useParams } from 'react-router-dom';

type Params = {
    period?: string
}

export default function InventoryReportPage() {
    const {t} = useTranslation()
    const {period} = useParams<Params>()
    const depositorId = useSelector(state => state.depositors.filter(d => d.fundData?.enabled).at(0)?.id)
    const [startDate, endDate] = getReportPeriodDates(period)
    const portfolio = usePortfolio()
    const currency: Currency = portfolio?.currency
    // Get inventory report
    const {data: report, isLoading, isError} = useQuery({
        queryFn: async () => {
            if (depositorId && startDate && endDate) {
                const data = await fetch(`/api/report/fund-inventory?depositorId=${depositorId}&startDate=${startDate}&endDate=${endDate}`)
                return await data.json() as FundInventoryReport
            }
        },
        queryKey: ['InventoryReport', depositorId, startDate, endDate]
    })
    const downloadPdfLink = `/api/report/fund-inventory/pdf?depositorId=${depositorId}&startDate=${startDate}&endDate=${endDate}&period=${period}&download=attachment`

    return (
        <Fragment>
            <PageHeader backToLink={'/reports/funds'} icon="ri-line-chart-line" title={t('pages-fund-reports.fundReports')}></PageHeader>
            {isLoading && (
                <LoadingSpinner/>
            )}
            {isError && (
                <Alert severity="error">{t('pages-fund-reports.errorLoadingReport')}</Alert>
            )}
            {!isError && !isLoading && report && currency && (
                <InventoryReport report={report}
                                 currency={currency}
                                 period={period}
                                 downloadPdfLink={downloadPdfLink}
                />
            )}
        </Fragment>
    )
}

type InventoryReportProps = FundInventoryReportPdfProps & {
    downloadPdfLink?: string
    pdfMode?: boolean
}
export function InventoryReport(
    {report, currency, period, downloadPdfLink, pdfMode=false}
        : InventoryReportProps) {
    const {t} = useTranslation()
    const Currency = useCurrencyOutputWithCurrency(currency)
    const noFundsInPeriod = report.fundPlacementInventoryEntries.length === 0
    const isPrint = useMediaQuery('print') || pdfMode

    return (
        <PageLayout style={{backgroundColor: 'white'}}>
            {isPrint && (
                <Box display={'flex'} justifyContent={'right'}>
                    <FixrateLogo className={styles.fixratePdfHeaderLogo}/>
                </Box>
            )}
            <Stack spacing={4} sx={{px: {xs: 2, md: 0}, maxWidth: '130rem'}}>
                <Stack>
                    {report?.toDate && <Box sx={{color: COLORS.PURPLE, fontWeight: 600, fontVariant: 'small-caps', textTransform: 'uppercase', mb: 1}}>{formatLongReportPeriod(period, t)}</Box>}
                    <Typography variant={'h1'}>{t('pages-fund-reports.inventoryReport')}</Typography>
                </Stack>
                {report && (
                    <Stack spacing={4}>
                        <Stack spacing={3}>
                            <Typography variant={'h2'}>{t('pages-fund-inventory-report.portfolio')}</Typography>
                            <Stack direction={{xs: 'column-reverse', lg: 'row'}} justifyContent={'space-between'} spacing={4}>
                                {noFundsInPeriod && <p>{t('pages-fund-inventory-report.portfolioEmptyState')}</p>}
                                <PortfolioSplitChart report={report}
                                                     pdfMode={isPrint}
                                />
                                <Stack spacing={2}>
                                    <Stack spacing={1}>
                                        <Box component="span" sx={{color: COLORS.SILVER_GRAY, fontWeight: 500}}>{t('pages-fund-inventory-report.valuePerDate', {date: DateOutput.formatDate(report.toDate)})}</Box>
                                        <Box component="span" sx={{fontSize: 22, fontWeight: 900}}>{Currency(report.outgoingBalance)}</Box>
                                    </Stack>
                                    <Stack spacing={0.5}>
                                        <Box component="span" sx={{color: COLORS.SILVER_GRAY, fontWeight: 500}}>{t('pages-fund-inventory-report.developmentInPeriod')}</Box>
                                        <Stack component="span" direction={'row'} spacing={1} alignItems={'center'} sx={{fontSize: 22, fontWeight: 900}}>
                                            {report.returnRates.CURRENT && <InterestBadge sx={{fontSize: '1.6rem'}} interest={report.returnRates.CURRENT}/>}
                                            <Box component="span">{Currency(report.returnValues.CURRENT)}</Box>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </Stack>
                            <InventoryTable report={report}
                                            currency={currency}
                            />
                        </Stack>
                        <Stack spacing={3} sx={{breakInside: 'avoid', pageBreakInside: 'avoid'}}>
                            <Typography variant={'h2'}>{t('pages-fund-inventory-report.fundsDevelopmentInPeriod')}</Typography>
                            <Box>
                                <Typography variant={'subtitle2'} sx={{mb: 1}}>
                                    {t('pages-fund-inventory-report.timeWeightedReturn')}
                                    {!isPrint && (
                                        <Tooltip arrow title={t('pages-fund-inventory-report.timeWeightedReturnInfo')}>
                                        <i className="ri-question-line" style={{marginLeft: '0.4rem', color: PURPLE[500]}}/>
                                        </Tooltip>
                                    )}
                                </Typography>
                                <FundsDevelopmentChart report={report}
                                                       pdfMode={isPrint}
                                />
                            </Box>
                        </Stack>
                        <Stack spacing={3}>
                            <Typography variant={'h2'}>{t('pages-fund-inventory-report.returns')}</Typography>
                            <Box sx={{maxWidth: '92rem', pageBreakInside: 'avoid', breakInside: 'avoid'}}>
                                <Typography variant={'subtitle2'} sx={{mb: 1}}>
                                    {t('pages-fund-inventory-report.moneyWeightedReturn')}
                                    {!isPrint && (
                                        <Tooltip arrow title={t('pages-fund-inventory-report.moneyWeightedReturnInfo')}>
                                            <i className="ri-question-line" style={{marginLeft: '0.4rem', color: PURPLE[500]}}/>
                                        </Tooltip>
                                    )}
                                </Typography>
                                <ReturnsTable type={'returnRates'}
                                              report={report}
                                              currency={currency}
                                />
                            </Box>
                            <Box sx={{pageBreakInside: 'avoid', breakInside: 'avoid'}}>
                                <Typography variant={'subtitle2'} sx={{mb: 1}}>{t('pages-fund-inventory-report.returnInKroner')}</Typography>
                                <ReturnsTable type={'returnValues'}
                                              report={report}
                                              currency={currency}
                                />
                            </Box>
                            <Box sx={{pageBreakInside: 'avoid', breakInside: 'avoid'}}>
                                <Typography variant={'subtitle2'} sx={{mb: 1}}>{t('pages-fund-inventory-report.contributionAnalysis')}</Typography>
                                <ContributionAnalysisChart report={report}
                                                           pdfMode={isPrint}
                                />
                            </Box>
                        </Stack>
                        {!isPrint && downloadPdfLink && (
                            <Box>
                                <Button href={downloadPdfLink}
                                        startIcon={<i className="ri-file-pdf-2-line"></i>}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        variant={'contained'}
                                >
                                    {t('pages-fund-inventory-report.downloadAsPdf')}
                                </Button>
                            </Box>
                        )}
                    </Stack>
                )}
            </Stack>
        </PageLayout>
    );
}
