import {addInfoMessage} from '#state/reducers/infoMessages'
import {translate} from '#services/i18n'

export async function handleStatusCodeErrors(dispatch, response) {
    const invariant = async function invariant(response, message, ...args) {
        if (response) {
            console.error([
                'Network Response',
                response.statusText,
                message,
            ].filter(Boolean))
        }

        return dispatch(addInfoMessage({type: 'error', ...args, message}))
    }.bind(null, response)

    if (response && !response.ok) {
        switch (response.status) {
            case 401:
            case 403:
            case 413:
            case 500: {
                return invariant(
                    translate('error.status.' + response.status + '.message'),
                    translate('error.status.' + response.status + '.header')
                )
            }

            default: {
                try {
                    if (response.headers.get('content-type') === 'application/json') {
                        const json = await response.clone().json()

                        if (json.messageKey) {
                            return invariant(translate('command.error.' + json.messageKey), translate('error.header'))
                        } else if (json.message) {
                            return invariant(json.message, translate('error.header'))
                        } else {
                            return invariant(translate('error.default'), translate('error.header'))
                        }

                    } else {
                        const text = await response.clone().text()
                        return invariant(
                            text && text.length < 300 ? text : translate('error.default'),
                            translate('error.header')
                        )
                    }
                } catch (err) {
                    console.log('Error finding error message: {}', err)
                    return invariant(translate('error.default'), translate('error.header'))
                }
            }
        }
    }
}

export const handleNetworkErrors = function (dispatch) {
    dispatch(addInfoMessage({
        type: 'error',
        message: translate('error.header'),
        detail: translate('error.network')
    }))
}

