import React, { useState } from 'react'
import { TextField } from '@mui/material'
import {CompanyInfo} from '#components/CompanySelect/CompanySelect'

type Props = {
    onChange: (value: CompanyInfo | null) => void
    setErrorMessage: (error: string) => void
    autoFocus?: boolean
    placeholder?: string
    regulatoryRegion?: ('NO' | 'SE')
}

function canonicalFormat(input: string): string {
    return input.replace(/\D/g, '')
}

export default function CompanySelectBasic({ onChange, setErrorMessage, autoFocus, placeholder, regulatoryRegion}: Props) {
    const [inputValue, setInputValue] = useState('')

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value
        setInputValue(newValue)

        const formattedValue = canonicalFormat(newValue)

        if (!formattedValue) {
            onChange(null)
            setErrorMessage('')
            return
        }

        onChange({
            nationalIdentity: formattedValue,
            companyType: 'UNKNOWN',
            name: '',
            nationality: regulatoryRegion
        })
        setErrorMessage('')
    }

    return (
        <TextField
            value={inputValue}
            onChange={handleInputChange}
            autoFocus={autoFocus}
            placeholder={placeholder || 'Enter organization number'}
            fullWidth
            inputProps={{
                'data-cy': 'companySearchInput'
            }}
        />
    )
}
