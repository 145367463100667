import {createSlice} from '@reduxjs/toolkit'

const slice = createSlice({
    name: 'depositDeviations',
    initialState: [],
    reducers: {
        updateDepositDeviations(state, action) {
            return action.payload
        }
    },
})

const {actions, reducer} = slice
export const {updateDepositDeviations} = actions
export default reducer
