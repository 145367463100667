import {createSlice} from '@reduxjs/toolkit'

const slice = createSlice({
    name: 'signatureStausIsLoading',
    initialState: {},
    reducers: {
        startSignatureStatusCheck(state, action) {
            state[action.payload] = true
        },
        signatureStatusCheckDone(state, action) {
            state[action.payload] = false
        },
    },
})

const {actions, reducer} = slice
export const {startSignatureStatusCheck, signatureStatusCheckDone} = actions
export default reducer