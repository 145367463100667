import styles from './LoadingSpinner.module.scss'
import {useTranslation} from 'react-i18next'
import classNames from 'classnames'

const LoadingSpinner = ({ className, text }: { className?: string, text?: string }) => {
    const { t } = useTranslation()

    return (
        <div
            className={classNames(styles.loadingSpinner, className ? className : null)}
            data-cy='loadingSpinner'
        >
            <div className={styles.loadingIcon}>
                <svg width='14' height='14' viewBox='0 0 38 38' xmlns='http://www.w3.org/2000/svg' stroke='#4D2A98'>
                    <g fill='none' fillRule='evenodd'>
                        <g transform='translate(1 1)' strokeWidth='2'>
                            <circle strokeOpacity='.3' cx='18' cy='18' r='18' />
                            <path d='M36 18c0-9.94-8.06-18-18-18'>
                                <animateTransform
                                    attributeName='transform'
                                    type='rotate'
                                    from='0 18 18'
                                    to='360 18 18'
                                    dur='1s'
                                    repeatCount='indefinite'
                                />
                            </path>
                        </g>
                    </g>
                </svg>
            </div>
            <p className={styles.loadingText}>{text || t('components-LoadingSpinner.loading')}</p>
        </div>
    )
}

export default LoadingSpinner
