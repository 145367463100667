import {createSlice} from '@reduxjs/toolkit'

const slice = createSlice({
    name: 'unresolvedTasks',
    initialState: [],
    reducers: {
        setUnresolvedTasks(state, action) {
            return action.payload
        },
    },
})

const {actions, reducer} = slice
export const {setUnresolvedTasks} = actions
export default reducer
