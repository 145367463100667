import {createSlice} from '@reduxjs/toolkit'

const slice = createSlice({
    name: 'products',
    initialState: {},
    reducers: {
        setProducts(state, action) {
            return action.payload.reduce((result, currentValue) => {
                result[currentValue.id] = currentValue
                return result
            }, {})
        },
    },
})

const {actions, reducer} = slice
export const {setProducts} = actions
export default reducer