import {createSlice} from '@reduxjs/toolkit'

const slice = createSlice({
    name: 'bank',
    initialState: null,
    reducers: {
        setBank(state, action) {
            return action.payload
        },
    },
})

const {actions, reducer} = slice
export const {setBank} = actions
export default reducer