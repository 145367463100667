import {createSlice} from '@reduxjs/toolkit'

const slice = createSlice({
    name: 'fundCustomers',
    initialState: [],
    reducers: {
        setFundCustomers(state, action) {
            return action.payload
        },
    },
})

const {actions, reducer} = slice
export const {setFundCustomers} = actions
export default reducer
