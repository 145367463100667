import update from 'immutability-helper'
import {removeField, setField} from '#app/services/immutable'
import {createSlice} from '@reduxjs/toolkit'

const slice = createSlice({
    name: 'depositor',
    initialState: null,
    reducers: {
        setDepositor(state, action) {
            return action.payload
        },
        setCustomerDeclarationValue(state, action) {
            if (!state) {
                return state
            }

            // Change field in customer declaration
            const newCustomerDeclaration = setField(state.customerDeclaration, action.payload.path, action.payload.value)

            // Create new state
            return update(state, {customerDeclaration: {$set: newCustomerDeclaration}})
        },
        deleteCustomerDeclarationValues(state, action) {
            if (!state) {
                return state
            }

            let customerDeclaration = state.customerDeclaration

            // Remove field in customer declaration
            action.payload.paths.forEach(path => {
                customerDeclaration = removeField(customerDeclaration, path)
            })

            // Create new state
            return update(state, {customerDeclaration: {$set: customerDeclaration}})
        },
    },
})

const {actions, reducer} = slice
export const {setDepositor, setCustomerDeclarationValue, deleteCustomerDeclarationValues} = actions
export default reducer
