import {POST} from './rest-connection'
import {syncSessionState} from '#services/thunks/session'

const SYNC_SESSION_PATH = '/api/session/lastseen'

async function handleNetworkErrors(dispatch, ex) {
    console.error('Network error when calling ' + SYNC_SESSION_PATH, ex)
    return Promise.reject('Network error when calling ' + SYNC_SESSION_PATH +  ex)
}

async function handleStatusCodeErrors(dispatch, response) {
    if (!response.ok) {
        if (response.status === 502) {
            try {
                const text = await response.text()
                console.warn('Received 502 from ' + SYNC_SESSION_PATH + ', resp: ', text)
            } catch (e) {
                console.warn('Received 502 from ' + SYNC_SESSION_PATH + ', failed to read response')
            }
            return Promise.reject()
        }
        if (response.status === 401) {
            dispatch(syncSessionState())
        }
    }
    return response
}

async function parser(response: Response): Promise<any> {
    try {
        return await response.json()
    } catch (err) {
        console.info(`Failed to parse response from ${SYNC_SESSION_PATH} as json`, err)
        return Promise.reject()
    }
}


const syncSession = (dispatch) => ({
    syncLastSeen: (lastSeen) => POST(
        dispatch,
        SYNC_SESSION_PATH,
        lastSeen,
        {
            handleStatusCodeErrors,
            handleErrors: handleNetworkErrors,
            parse: parser
        }
    ),
})
export default syncSession
