import { useEndpoint } from '#app/services/beta';
import { Currency } from '@fixrate/fixrate-query';
import { setPortfolioFilter } from '#app/state/reducers/portfolioFilter';
import { useSelector } from '#app/state/useSelector';
import { useDispatch } from 'react-redux';
import { CurrencySelection } from './CurrencySelection';
import { sendExceptionToSentry } from '#app/services/sentry';
export const BankCurrencySelection = () => {
    const bank = useSelector(state => state.bank)
    const selectedCurrency = useSelector(state => state.session?.association?.currentCurrency)
    const dispatch = useDispatch()
    const currencies = bank?.currencies
    const { setCurrencyBankSession } = useEndpoint()

    const resetPortfolioFilter = () => {
        dispatch(setPortfolioFilter({
            depositorId: undefined,
        }))
    }

    const switchCurrency = (currency: Currency) => {
        setCurrencyBankSession(currency).then(() => resetPortfolioFilter()).catch((err) => {
            sendExceptionToSentry(err, { currency: currency, bankId: bank.id})
        })
    }

    return currencies ? (
        <CurrencySelection currencies={currencies} selectedCurrency={selectedCurrency} switchCurrency={switchCurrency} />
    ) : null
}
