import {createSlice} from '@reduxjs/toolkit'

const slice = createSlice({
    name: 'uisettings',
    initialState: {},
    reducers: {
        setUiSettings(state, action) {
            return action.payload
        },
        setUiSetting(state, {payload}) {
            state[payload.key] = payload.value
        },
        removeUiSetting(state, {payload}) {
            delete state[payload.key]
        }
    },
})

const {actions, reducer} = slice
export const {setUiSettings, setUiSetting, removeUiSetting} = actions
export default reducer
